@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.map {
  width: 450px;
  height: calc(100vh - 44px);
  position: relative;

  .icon-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    transform: translate(0%, calc(-100% - 32px));

    &:after, &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    &:before {
      transition: opacity .1s ease;
      height: 26px;
      width: 2px;
      background-color: $brandColor;
    }

    &:after {
      transition: width, height, margin-top, border-color 0.1s ease;
      margin-top: 26px;
      width: 8px;
      height: 8px;
      border-color: transparent;
      border-radius: 50%;
      background-color: $brandColor;
    }
  }

  .placemark_layout_container{
    position: relative;
  }

  .marker {
    width: 81px;
    height: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $brandColor;
    border-radius: 50%;

    img {
      width: 67px;
      height: 67px;
      border: 2px solid white;
      border-radius: 50%;
      transition: all .1s ease;
      object-fit: cover;
    }
  }

  .marker-title {
    width: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: opacity .1s ease;
    font-size: 12px;
    border-radius: 4px;
    background-color: $brandColor;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    padding: 5px 10px;
  }

  &__btn{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $brandColor;
    color: black;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 2;
    transition: all 0.2s;

    svg{
      width: 36px;
      height: 36px;
    }
    &:hover{
      background-color: $brandSecColor;
    }
  }

  @media all and (max-width: 1400px) {
    width: 350px;
  }

  @media all and (max-width: 1200px) {
    width: 300px;
  }

  @media all and (max-width: 1080px) {
    display: none;
  }
}
