@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.HouseWrapper {
  .ContentWrapper {
    padding-top: 80px;

    .HeaderWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;

      &.hidden {
        display: none;
      }

      .header-left-wrapper {
        padding: 0 0 0 $mainPadding;
        margin-top: 20px;

        .header-title {
          font-size: 20px;
          font-family: Bold;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .header-right-wrapper {
        display: flex;
        align-items: center;
        padding: 0 $mainPadding;
        margin-top: 20px;

        .building-wrapper {
          height: 50px;
          margin-right: 20px;

          img, svg {
            height: 100%;
            border-radius: 2px;

            path {
              transition: .2s;
            }

            &:hover {
              path {
                fill: $brandColor
              }
            }
          }
        }

        .item-card {
          display: flex;
          align-items: center;

          .icon-wrapper {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background-color: #F5F6F7;
            margin-right: 20px;
            transition: .2s;

            &:hover {
              background-color: $brandColor;
            }

            svg {
              path {
                fill: #202020;
              }
            }
          }

          .item-card-content-title {
            color: #BFBCC2;
            font-family: Medium;
            margin-bottom: 2px;
            font-size: 14px;
          }

          .item-card-content-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: Medium;
            font-size: 16px;
          }
        }
      }
    }

    .FloorWrapper {
      padding-bottom: $mainPadding;
    }

    .FloorMainContent {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: calc(#{$mainPadding} / 2) $mainPadding 0;

      .house-details-checkbox {
        font-family: Bold;
        white-space: normal;

        .ant-radio-inner {
          width: 18px;
          height: 18px;

          //&::after {
          //  width: 10px;
          //  height: 10px;
          //}
        }

        .ant-radio-input:focus + .ant-radio-inner {
          -webkit-box-shadow: none;
          box-shadow: none;
          border-color: $brandColor;
        }

        .ant-radio-checked {

          .ant-radio-inner {
            border-color: $brandColor;

            &::after {
              background-color: $brandColor;
            }
          }

          &::after {
            border: 1px solid $brandColor;
          }
        }
      }

      .LeftSide {
        width: calc(50% - 20px);

        .checkboxes-wrapper {
          min-height: 220px;

          .ant-radio-group {
            margin: 12px 0 20px;

            .house-details-checkbox {
              margin-top: 8px;
            }
          }
        }

        .left-side-slider {
          display: flex;
          align-items: flex-end;

          .slider-wrapper {
            width: calc(100% - 180px);
            background-color: white;
            padding: 0 4px;
            border-radius: 0 0 8px 8px;
            position: relative;
            z-index: 2;


            .images-list-wrapper {
              width: calc(100% + 5px);
              margin-top: 12px;
              position: relative;
              cursor: pointer;

              .slick-track {
                margin-left: unset;
              }

              .slick-slide {
                padding-right: 12px;
                outline: none;

                .list-item {
                  width: 100%;
                  padding-top: 100%;
                  position: relative;
                  cursor: pointer;
                  border-radius: 8px;

                  &:focus {
                    outline: none;
                  }

                  .list-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 5px;
                    background-color: white;

                    &:focus {
                      outline: none;
                    }
                  }

                  &.active::after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    width: 100%;
                    height: 2px;
                    background: $brandColor;
                  }

                }
              }

              &:hover .slider-btn {
                visibility: visible;
              }

              .slider-btn {
                visibility: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                transition: .2s;
                background-color: white;
                box-shadow: 0 1px 4px 0 rgba(10, 12, 11, .2);

                img, svg {
                  width: 12px;
                  height: 12px;

                  path {
                    fill: black;
                  }
                }

                &:hover svg {
                  path {
                    fill: $brandColor;
                  }
                }

                &:disabled {
                  visibility: hidden;
                }

                &.prev-btn {
                  left: -16px;
                  position: absolute;
                  top: calc(50% - 4px);
                  transform: translateY(-50%);
                }

                &.next-btn {
                  right: -4px;
                  position: absolute;
                  top: calc(50% - 4px);
                  transform: translateY(-50%);
                }
              }
            }

            .main-img-wrapper {
              width: 100%;
              position: relative;
              overflow: hidden;
              border-radius: 8px;
              background-color: white;

              .main-img-content {
                position: relative;
                width: 100%;
                padding-top: 71.4%;

                .main-img {
                  border-radius: 8px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center;
                }
              }

              .product-current-img {
                width: 100% !important;
                height: 100% !important;
              }

              .mobile-slider-img {
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
                display: none;
              }
            }

            &.other-projects-images-list {
              width: calc(8 * 50px + 126px);
            }
          }


          .image-loader {
            width: 90%;
            height: 45vh;
            background: #f5f5f5;
          }
        }

        .identical-houses-list {
          padding: 15px;
          box-shadow: 0 0 7px 4px #f8f8f8;
          max-width: calc(8 * 50px + 126px);
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(57px, 1fr));

          .list-item {
            width: 50px;
            height: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #F5F5F5;
            margin: 6px;
            border-radius: 4px;
            font-size: 18px;
            font-family: Bold;
            cursor: default;
            transition: .2s;

            &.active {
              background-color: $brandColor;
            }

            &.inactive-floor {
              cursor: default;
              background-color: $darkGreyColor;
            }
          }
        }

        .badges-wrapper {
          padding: 30px 0;
          display: flex;
          flex-wrap: wrap;
          max-width: calc(8 * 50px + 126px);
          justify-content: space-between;

          .badge-item {
            display: flex;
            margin-right: 10px;

            .badge-color {
              width: 20px;
              height: 20px;
              border-radius: 4px;
              margin-right: 10px;
            }
          }

          .compass-wrapper-in-right {
            display: none;
          }
        }

        .compass-wrapper {
          position: relative;
          z-index: 1;
          width: 150px;
          display: flex;
          align-items: flex-end;
          //animation: rotate 1.6s ease-in-out;
          //animation-iteration-count: 1;

          img {
            width: 100%;
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(30deg);
            }
            50% {
              transform: rotate(-15deg);
            }
            75% {
              transform: rotate(15deg);
            }
            100% {
              transform: rotate(0deg);
            }
          }
        }
      }

      .RightSide {
        width: 50%;
        position: relative;

        .view-360 {
          position: absolute;
          top: -80px;
          right: 0;
          z-index: 4;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        .upload-pdf-wrapper {
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          font-family: Regular;
          margin-bottom: 8px;

          .upload-btn {
            display: flex;
            align-items: center;
            background-color: inherit;
            color: #000;
            font-family: Medium;
            transition: .2s;
            cursor: default;

            &.active:hover {
              cursor: pointer;
              color: #BFBCC2;
            }

            &.active:hover svg {
              path {
                fill: #BFBCC2;
              }
            }

            svg {
              width: 20px;
              margin-left: 10px;

              path {
                transition: .2s;
              }
            }
          }
        }

        .header-title {
          font-size: 20px;
          font-family: Bold;
          margin-bottom: 20px;
        }

        .areas-list {
          margin-bottom: 20px;

          .list-item {
            display: flex;
            align-items: center;
            margin-top: 12px;
            font-size: 15px;

            .item-name {
              margin-right: 8px;
            }

            .line {
              flex-grow: 1;
              border-top: 1px dotted #BFBCC2;
            }

            .item-area {
              margin-left: 8px;
              font-family: Medium;
            }
          }

          .house-details-checkbox {
            font-size: 18px;
            margin-bottom: 20px;
            margin-top: 20px;

            .ant-radio-inner {
              top: -2px;
            }
          }
        }

        .price-wrapper {
          background-color: #F5F5F5;
          padding: 20px 30px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;

          .price {
            display: flex;
            align-items: center;
            font-size: 22px;
            font-family: Medium;

            svg {
              width: 20px;
              height: 20px;
              margin-top: -3px;
            }
          }

          .PrimaryButton {
            margin-left: auto;

            &:disabled {
              background-color: #dadada !important;
            }
          }
        }

        .linkWrapper {
          line-height: 26px;
          letter-spacing: 0.4px;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          font-size: 13px;

          .link-text {
            color: #007AFE;
            text-decoration: underline;
            cursor: pointer;
            margin-right: 20px;
          }

          .monthly-price-wrapper {
            display: flex;
            align-items: center;

            .monthly-price {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-family: Bold;
              margin-left: 8px;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  @media all and (min-width: 1600px) {
    .ContentWrapper {
      .FloorMainContent {
        .LeftSide {
          width: calc(45% - 20px);
        }

        .RightSide {
          width: 55%;
        }
      }
    }
  }

  @media all and (max-width: 1400px) and (min-width: 1201px) {
    .ContentWrapper {
      .FloorMainContent {
        .LeftSide {
          width: calc(55% - 20px);
        }

        .RightSide {
          width: 45%;
        }
      }
    }
  }
  @media all and (max-width: 1200px) and (min-width: 1001px) {
    .ContentWrapper {
      .FloorMainContent {
        padding: calc(#{$mainPadding} / 2) calc(#{$mainPadding} / 2) $mainPadding;

        .LeftSide {
          width: calc(57% - 20px);
        }

        .RightSide {
          width: 43%;

          .price-wrapper {
            padding: 16px;

            .price {
              font-size: 22px;
            }

            .PrimaryButton {
              font-size: 15px;
              height: 40px;
              padding: 0px 20px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1000px) {
    .ContentWrapper {

      .FloorMainContent {
        flex-direction: column;

        .LeftSide {
          flex-direction: row;
          width: 100%;

          .compass-wrapper {
            display: none;
          }

          .checkboxes-wrapper {
            min-height: unset;

            .slider-wrapper {
              margin-bottom: 20px;
            }
          }

          .slider-wrapper {
            width: 100% !important;
          }

          .image-loader {
            width: 100%;
            height: 45vh;
          }
        }

        .RightSide {
          width: 100%;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .ContentWrapper {

      .FloorMainContent {
        padding: calc(#{$mainPadding} / 2) $mainPadding 60px;

        .LeftSide {

          .compass-wrapper {
            display: none;
          }

          .image-loader {
            width: 100%;
            height: 38vh;
          }

        }

        .RightSide {
          .price-wrapper {
            padding: 14px 12px;

            .price {
              font-size: 20px;
            }

            .PrimaryButton {
              font-size: 15px;
              height: 40px;
              padding: 0px 20px;
            }
          }
        }
      }
    }
  }
}

.pdfs-wrapper {
  min-width: 150px !important;

  .ant-dropdown-menu {
    .ant-dropdown-menu-item:not(:last-child) {
      border-bottom: 1px solid $greyBorderColor;
    }
  }

  .upload-btn {
    padding-right: 0 !important;
  }
}