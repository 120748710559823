

.progress-wrapper{
  display: none;
  width: calc(100% - 48px);
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 16px;
  right: 24px;
  left: 24px;
  z-index: 100;
  &.show-on-desktop{
    display: flex;
  }
  .progress-item{
    height: 3px;
    cursor: pointer;
    background: #E8E8E8;
    position: relative;
    border-radius: 3px;
    .stroke {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 3px;
      z-index: 10;
      background: #FADA45;
      display: block;
      border-radius: 5px;
      will-change: width;
    }
    .animate {
      animation-duration: 5s;
      animation-fill-mode: both;
      animation-name: progress;
    }
  }

  @media screen and (max-width: 1050px){
    display: flex;
    width: calc(100% - 32px);
    left: 16px;
    right: 16px;
  }
}
@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
