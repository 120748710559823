@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.filters-page {
  display: flex;
  justify-content: space-between;
  //padding-top: $headerHeight;
  background-color: $bgGreyColor;
  //height: calc(100vh);

  .closeFilterMap{
    display: none;
    z-index: -1;
  }
  .showFilterMap{
    display: flex;
  }

  &__projects {
    width: calc(100% - 458px);
    height: 100%;
    background-color: white;

    &__header {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid $bgGreyColor;

      &.ant-breadcrumb {
        padding: 0 25px;
      }
    }

    &__content {
      width: 100%;
      height: calc(100% - 44px);
      display: flex;
      font-size: 14px;

      &__filter {
        width: 100%;
        height: 64px;
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid $bgGreyColor;

        .sort {
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          //min-width: 176px;
          position: relative;

          &__label {
            color: #8D8D8D;
            margin-right: 4px;
          }


          .custom-input-group {
            width: 100%;

            .custom-select {
              border-bottom: 0 !important;

              .ant-select-selector {
                .ant-select-selection-item {
                  font-size: 14px;
                  line-height: 34px;
                }
              }
            }

            .ant-select-dropdown {
              width: 270px !important;
              //right: 0!important;
              //left: unset!important;
            }
          }

        }

        .sort_count {
          width: 50%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: right;
        }
      }

      &__filter_btn {
        display: none;
      }

      &__list {
        width: calc(100% - 400px);
        border-right: 8px solid $bgGreyColor;
        height: calc(100vh - 44px);
        overflow-y: auto;
        @include custom-scrollbar-y;

        &::-webkit-scrollbar {
          width: 0;
          cursor: pointer;
        }

        .filter_projects {
          &__project {
            padding: 25px;
            border-bottom: 2px solid $bgGreyColor;
            border-top: 2px solid $bgGreyColor;
            background-color: #FFFDEF;
            position: relative;

            .info {
              display: flex;
              justify-content: space-between;

              &__image {
                width: 132px;
                height: 132px;
                border: 1px solid $brandColor;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 125px;
                  height: 125px;
                  object-fit: cover;
                }
              }

              &__content {
                width: calc(100% - 200px);
                padding: 0 20px;

                .project_title {
                  font-size: 32px;
                  line-height: 52px;
                  letter-spacing: 0.03em;
                  text-transform: uppercase;
                  color: #222;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .project_address {
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: 0.06em;
                  color: #656565;
                  margin-bottom: 24px;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .PrimaryButton {
                  font-size: 12px;
                }
              }

              &__view {
                margin-top: 10px;
                min-width: 60px;
                position: relative;

                svg {
                  position: absolute;
                  right: 0;
                  width: 30px;
                  height: 30px;
                }
              }

              &__action {
                width: 82px;
              }
            }

            .project-actions {
              display: flex;
              justify-content: space-between;
              //padding-left: 154px;
              width: calc(100% - 202px);
              position: absolute;
              bottom: 25px;
              right: 25px;

              .buildings-btn {
                display: flex;
                align-items: center;

                .PrimaryButton {
                  width: 36px;
                  height: 36px;
                  font-size: 15px;

                  &:last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
      }

      &__more {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        border-bottom: 10px solid $bgGreyColor;
        font-family: Bold;

        .more-btn {
          display: flex;
          align-items: center;
          margin-right: 12px;
          cursor: pointer;

          .svg {
            width: 20px;
            height: 20px;
            background: #F5F4F2;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            margin-right: 12px;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      &__map {
        width: 450px;
        height: 100%;

        .icon-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-box-align: center;
          transform: translate(0%, calc(-100% - 32px));

          &:after, &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
          }

          &:before {
            transition: opacity .1s ease;
            height: 26px;
            width: 2px;
            background-color: $brandColor;
          }

          &:after {
            transition: width, height, margin-top, border-color 0.1s ease;
            margin-top: 26px;
            width: 8px;
            height: 8px;
            border-color: transparent;
            border-radius: 50%;
            background-color: $brandColor;
          }


        }

        .marker {
          width: 81px;
          height: 81px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $brandColor;
          border-radius: 50%;

          img {
            width: 67px;
            height: 67px;
            border: 2px solid white;
            border-radius: 50%;
            transition: all .1s ease;
          }
        }

        .marker-title {
          width: auto;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
          transition: opacity .1s ease;
          font-size: 12px;
          border-radius: 4px;
          background-color: $brandColor;
          color: black;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          z-index: 1;
          padding: 5px 10px;
        }
      }
    }
  }

  &:last-child {
    .filter_projects__project {

    }
      .filters-page__projects__content {
        .filters-page__projects__content__more {
          border-bottom: 0;
        }
      }
  }

  @media all and (max-width: 1400px) {
    &__projects {
      width: calc(100% - 358px);

      &__content {
        &__list {
          width: calc(100% - 350px);

          .filter_projects {
            &__project {
              .info {

                &__content {

                  .project_title {
                    font-size: 28px;
                    line-height: 46px;
                  }

                  .project_address {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }

        &__map {
          width: 350px;
        }
      }
    }
  }

  @media all and (max-width: 1200px) {

    &__projects {
      width: calc(100% - 308px);

      &__content {
        &__list {
          width: calc(100% - 300px);

          .filter_projects {
            &__project {
              .info {
                &__image {
                  width: 102px;
                  height: 102px;

                  img {
                    width: 97px;
                    height: 97px;
                  }
                }

                &__content {
                  width: calc(100% - 170px);
                  padding: 0 14px;

                  .project_title {
                    font-size: 22px;
                    line-height: 34px;
                  }

                  .project_address {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }

              .project-actions {
                width: calc(100% - 166px);
              }
            }
          }
        }

        &__map {
          width: 300px;

        }
      }
    }
  }

  @media all and (max-width: 1080px) {
    &__projects {
      width: calc(100% - 288px);

      &__content {
        &__list {
          border: 0;
          width: calc(100%);

          .filter_projects {
            &__project {
              .info {
                &__content {

                  .project_title {
                    font-size: 22px;
                    line-height: 34px;
                  }

                  .project_address {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }

        &__map {
          display: none;
        }
      }
    }
  }

  @media all and (max-width: 760px) {
    flex-direction: column;

    &__mobile {
      opacity: 1;
      position: fixed;
      width: 100%;
      height: 100vh;
      background-color: white;
      z-index: 11;
      top: 0;
      padding: 80px 14px;
      transition: opacity 0.2s;

      .PrimaryButton {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0;
        font-size: 14px;
      }
    }

    &__projects {
      width: 100%;

      &__content {
        &__filter {
          //display: none;
        }

        &__filter_btn {
          display: block;
          margin: 15px auto;
          font-size: 16px;
          height: 44px;

          .title {
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
            }
          }
        }

        &__list {
          .filter_projects {
            &__project {
              padding: 12px;

              .info {

                &__content {
                  padding: 0 10px;

                  .project_title {
                    font-size: 18px;
                    line-height: 32px;
                  }

                  .project_address {
                    font-size: 13px;
                    line-height: 20px;
                  }
                }

                &__view {
                  margin-top: 4px;
                  font-size: 13px;
                }
              }

              .project-actions {
                width: calc(100% - 142px);
                bottom: 12px;
                right: 12px;
              }
            }
          }
        }

        &__map {
          display: none;
        }
      }
    }
  }

  @media all and (max-width: 500px) {

    &__projects {
      &__content {
        &__list {
          .filter_projects {
            &__project {
              .info {
                &__image {
                  width: 78px;
                  height: 78px;

                  img {
                    width: 70px;
                    height: 70px;
                  }
                }

                &__content {
                  width: calc(100% - 137px);
                }
              }

              .project-actions {
                margin-top: 18px;
                position: unset;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
