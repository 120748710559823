@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

$light-grey: #c9c6c6;

//.custom-modal{
//  width: 700px;
//}
.slider-modal-wrapper {
  width: 900px;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid $greyColor;
    padding: 16px;
    border-radius: 8px 8px 0 0;
    font-size: 18px;
    font-weight: bold;
  }

  .slider-wrapper {
    width: 100%;
    background-color: #f4f4f4;
    padding: 16px;
    border-radius: 0 0 8px 8px;


    .images-list-wrapper {
      width: calc(100% + 5px);
      margin-top: 12px;
      position: relative;
      cursor: pointer;

      .slick-track {
        margin-left: unset;
      }

      .slick-slide {
        padding-right: 12px;
        outline: none;

        .list-item {
          width: 100%;
          padding-top: 100%;
          position: relative;
          cursor: pointer;
          border-radius: 8px;

          &:focus {
            outline: none;
          }

          .list-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 5px;
            background-color: white;

            &:focus {
              outline: none;
            }
          }

          &.active::after {
            content: '';
            position: absolute;
            bottom: -4px;
            width: 100%;
            height: 2px;
            background: $brandColor;
          }

        }
      }

      &:hover .slider-btn {
        visibility: visible;
      }

      .slider-btn {
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        transition: .2s;
        background-color: white;
        box-shadow: 0 1px 4px 0 rgba(10, 12, 11, .2);

        img, svg {
          width: 12px;
          height: 12px;

          path {
            fill: black;
          }
        }

        &:hover svg {
          path {
            fill: $brandColor;
          }
        }

        &:disabled {
          visibility: hidden;
        }

        &.prev-btn {
          left: -12px;
          position: absolute;
          top: calc(50% - 4px);
          transform: translateY(-50%);
        }

        &.next-btn {
          right: -6px;
          position: absolute;
          top: calc(50% - 4px);
          transform: translateY(-50%);
        }
      }
    }

    .main-img-wrapper {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      background-color: white;

      .main-img-content {
        position: relative;
        width: 100%;
        padding-top: 71.4%;

        .main-img {
          border-radius: 8px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .product-current-img {
        width: 100% !important;
        height: 100% !important;
      }

      .mobile-slider-img {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        display: none;
      }
    }
  }


  .dialog-closing-btn {
    background-color: transparent;
    display: flex;
    align-items: center;

    img, svg {
      width: 32px;

      polygon {
        fill: #202020;
      }
    }
  }
}

@media screen and (max-width: 1400px){
  .slider-modal-wrapper{
    width: 760px;
  }
}
@media all and (max-width: 800px) {
  .slider-modal-wrapper {
    width: 600px;

    .slider-modal-content {
      width: 100%;
      margin-top: 50px;
    }
  }
}
@media all and (max-height: 800px) {
  .slider-modal-wrapper {
    width: 640px;
  }
}


@media all and (max-width: 600px) {

  .ant-modal-content {

    width: 100%;

    .slider-modal-wrapper {

      width: 100%;
    }
  }
}


.ant-modal-content {
  box-shadow: unset !important;
}