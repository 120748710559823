@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.filters {
  width: 450px;
  padding: 80px 45px;
  background-color: white;

  .filter-item {
    margin-bottom: 40px;
  }

  .rooms-count {
    display: flex;
    cursor: pointer;
    align-items: center;

    &__title {
      margin-right: 8px;
    }

    &__list {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #484848;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: content-box;
      color: #656565;
      font-size: 17px;
      line-height: 28px;
      letter-spacing: 0.03em;
      background-color: transparent;

      &:hover {
        color: $brandColor;
      }

      &.active {
        color: $brandColor;
      }

      &:disabled {
        cursor: no-drop;
        color: #656565;
      }

    }

    .line {
      width: 1px;
      height: 20px;
      background-color: $greyBorderColor;
      margin: 0 12px;
    }
  }

  .dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid $greyBorderColor;
    height: 50px;
    background: white;
    padding: 12px 0;
    color: #8D8D8D;

    div {
      width: 100%;
    }

    svg {
      transition: all 0.2s;
      transform-origin: center;

      path {
        fill: $greyBorderColor
      }
    }

    &.ant-dropdown-open {
      border-bottom: 1px solid $brandColor;

      svg {
        transform: rotate(180deg);

        path {
          fill: black;
        }
      }
    }
  }

  .clear-filter {
    color: #EC4B1E;
    margin: 40px 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    cursor: pointer;
  }

  .PrimaryButton {
    width: 100%;
  }

  .dark-text {
    color: #484848;
    font-family: Medium;
  }

  &__close {
    display: none;
  }

 /* .house-filter-checkbox {
    font-family: Bold;
    white-space: normal;

    .ant-radio-inner {
      width: 18px;
      height: 18px;

      &::after {
        width: 10px;
        height: 10px;
      }
    }

    .ant-radio-input:focus + .ant-radio-inner {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: $brandColor;
    }

    .ant-radio-checked {

      .ant-radio-inner {
        border-color: $brandColor!important;

        &::after {
          background-color: $brandColor!important;
        }
      }

      &::after {
        border: 1px solid $brandColor!important;
      }
    }
  }*/

  @media all and (max-width: 1400px) {
    width: 350px;
    padding: 80px 25px;
  }

  @media all and (max-width: 1200px) {
    width: 300px;
    padding: 80px 25px;
  }

  @media all and (max-width: 1080px) {
    width: 280px;
    padding: 80px 14px;

    .filter-item {
      margin-bottom: 26px;
    }

    .rooms-count {

      &__title {
        margin-right: 6px;
      }

      &__list {
        font-size: 14px;
        line-height: 22px;
      }

      &__item {
        font-size: 15px;
        line-height: 28px;
      }

      .line {
        height: 16px;
        margin: 0 9px;
      }
    }

    .status-sort {
      .dropdown-btn {
        div {
          font-size: 14px;
        }
      }
    }
  }

  @media all and (max-width: 760px) {
    opacity: 0;
    height: 0;
    padding: 0;
    //display: none;

    &__close {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;

        polygon {
          fill: black;
        }
      }
    }
    &.mobile {
      opacity: 1;
      position: fixed;
      width: 100%;
      height: 100vh;
      background-color: white;
      z-index: 11;
      top: 0;
      padding: 80px 14px;
      transition: opacity 0.2s;

      .PrimaryButton {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0;
        font-size: 14px;
      }
    }
  }
}