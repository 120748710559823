@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.NotFoundWrapper {
  padding: 40px $mainPadding;
  font-size: 40px;
  font-family: Medium;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 354px);
  color: $brandColor;
}
