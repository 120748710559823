@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.HomepageContact {
  display: flex;
  align-items: center;
  justify-content: center;

  .HomepageContactBlock {
    width: $mainContent;
    background-color: rgba(250, 218, 69, 0.8);
    padding: 70px;
    border-radius: 20px;

    .LeftSide {
      .title {
        font-size: 44px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #222222;
        margin-bottom: unset;
      }

      .description {
        font-size: 21px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.04em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #222222;
        margin-top: 16px;
      }
    }

    .RightSide {
      width: 90%;
      display: flex;
      align-items: center;
      margin-top: 40px;

      .custom-input-group {
        flex-grow: 1;
        margin-right: 24px;

        input {
          height: 50px;
          margin-bottom: 0;
          border: none;
          padding-left: 20px;
          background-color: white;
          border-radius: 8px;


          &.invalid {
            color: #FF0000 !important;
            outline: none;

            &::placeholder {
              color: #FF0000 !important;
            }
            &:focus{
              color: black!important;
              caret-color: black!important;
              &::placeholder{
                color: $greyBorderColor!important;
              }
            }
          }
        }
      }

      .PrimaryButton {
        border-radius: 8px;
        height: 50px;
        background-color: #222222 !important;
        border: unset !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.03em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FEFEFE;
        text-transform: uppercase;
        padding: 0 82px !important;

        .anticon-loading {
          margin-left: 10px;

          //svg {
          //  path {
          //    fill: #202020;
          //  }
          //}
        }

        .tick-icon {
          height: 24px;
          width: 24px;
          margin-left: 10px;
          transition: all 0.2s;
          visibility: visible;

          //path {
          //  fill: #202020;
          //}
        }

        .show-tick {
          visibility: visible;
          opacity: 1;
        }

        .hide-tick {
          visibility: hidden;
          opacity: 0;
          width: 0;
          margin-left: 0;
        }

        &:hover {
          background: #222222 !important;
        }
      }
    }

    .BottomBlock {
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #222222;
      margin-top: 98px;

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.02em;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #0673E4;
        margin-left: 6px;
      }
    }
  }


  @media all and (max-width: 1024px) {

    .HomepageContactBlock{
      width: 100%;
      border-radius: unset;
      padding: 40px 24px;

      .LeftSide{
        .title{
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0.02em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .description{
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.04em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }

      .RightSide{
        width: 100%;
        flex-direction: column;
        .custom-input-group{
          width: 100%;
          margin-right: unset;
          margin-bottom: 24px;
        }
        .PrimaryButton{
          width: 100%;
        }
      }

      .BottomBlock {
        margin-top: 40px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;

        a{
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.02em;
          text-align: left;
        }
      }
    }
  }

}
