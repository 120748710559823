@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.partners-wrapper{
  padding: 160px $mainPadding ;
  .partners-title{
    font-size: 40px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #222222;
    display: flex;
    flex-direction: column;
  }
  .partners-block{
    position: relative;
    margin-top: 42px;

    .slick-slider {
      .slick-list {
        .slick-track {
          margin-left: auto;
          display: flex;
          align-items: center;
          .partner-item {
            width: 100%;
            height: 100%;
            padding-right: 60px;

            .partner-content {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              .fade-image {
                width: 100%;
                height: 100%;
                transition: 150ms all;
                //height: 139px;
                //filter: grayscale(100%);
              }
              .hover-image{
                display: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 150ms all;
              }

              &:hover{
                .fade-image{
                  display: none;
                }
                .hover-image{
                  display: unset;
                }
              }
            }
          }
        }
      }
    }

  }

  @media screen and (max-width: 1024px){
    padding: 56px $mainPadding 36px $mainPadding;
    .partners-title{
      font-size: 24px;
      line-height: 29.3px;
      letter-spacing: 0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .partners-block{
      margin-top: 28px;
      .slick-slider {
        .slick-list {
          .slick-track {
            .partner-item {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}