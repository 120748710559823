@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.AboutFutures {
  //padding: 0 $mainPadding;
  position: relative;
  width: $mainContent;
  margin: auto;
  margin-bottom: 140px;

  .blur-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    z-index: 1;
    background-color: $brandColor;
    filter: blur(70px);
    transform: translate(0%, 70%);

    &.right {
      right: 0;
      top: 0;
      bottom: unset;
      left: unset;
      transform: translate(-50%, 50%);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 85px;

    &__title {
      font-size: 40px;
      font-weight: 700;
      line-height: 72px;
      letter-spacing: 0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      display: flex;
      color: #222222;
      span{
        margin-right: 8px;
      }
    }

    &__question {
      font-size: 19px;
      line-height: 140%;
      letter-spacing: 0.03em;
      color: #484848;
      span{
        color: #222222;
        font-family: Medium;
        font-size: 19px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.03em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        .link{
          font-size: 19px;
          line-height: 140%;
          letter-spacing: 0.03em;
          text-decoration-line: underline;
        }
      }
    }
  }

  .futures-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 10;

    &__item{
      width: 26%;
      span{
        display: block;
      }
      svg{
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }
      .item_title{
        font-size: 28px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #222222;
        padding-bottom: 16px;
        border-bottom: 2.4px solid #FADA45
      }
      .item_description{
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.025em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #222222;
        margin-top: 16px;
      }
    }
  }
  .mob-question{
    display: none;
    span{
      color: #222222;
      font-family: Medium;
    }
  }

  @media all and (max-width: 1024px){
    margin-bottom: 72px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: unset;
      padding: 0 $mainPadding;


      &__title {
        font-size: 24px;
        line-height: 29.3px;
        letter-spacing: 0.01em;
        text-align: left;
        span{
          margin-right: 8px;
        }
      }

      &__question {
        display: none;
      }
    }
    .futures-list{
      padding: 0 $mainPadding;
      display: flex;
      flex-direction: column;

      &__item{
        width: 90%;
        margin-top: 40px;
      }
    }
  }
}
