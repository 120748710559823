@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.CalcWrapper {
  //padding-top: 15px;

  .ContentWrapper {

    .HeaderWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: $mainContent;
      margin: auto;
      margin-bottom: 20px;

      .header-left-wrapper {
        margin-top: 20px;

        .header-title {
          font-size: 20px;
          font-family: Bold;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .item-card {
        display: flex;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;

        .icon-wrapper {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background-color: #F5F6F7;
          margin-right: 20px;
          transition: .2s;

          &:hover {
            background-color: $brandColor;
          }

          svg {
            path {
              fill: #202020;
            }
          }
        }

        .item-card-content-title {
          color: #BFBCC2;
          font-family: Medium;
          margin-bottom: 2px;
          font-size: 14px;
        }

        .item-card-content-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: Medium;
          font-size: 16px;
        }
      }
    }

    .CalcMainContent {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: $mainContent;
      margin: auto;
      padding: calc(#{$mainPadding} / 2) 0;

      .calc-title {
        font-size: 20px;
        font-family: Bold;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        .bank-icons {
          height: 100%;
          display: flex;
          flex-wrap: nowrap;

          .bank-icon-wrapper {
            padding: 4px 12px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            cursor: pointer;
            border: 1px solid white;

            &:last-child {
              margin-left: 10px;
            }

            .bank-icon {
              height: 100%;
            }

            svg {
              width: 122px;
              height: 15px;
            }

            &.selected {
              border-color: $brandColor;
            }
          }
        }
      }

      .LeftSide {
        display: flex;
        flex-direction: column;
        width: calc(60% - 30px);

        .custom-input-group {
          margin-bottom: 20px;

          input {
            padding: 12px;

            &:focus {
              box-shadow: none !important;
              outline: none;
              border-bottom: 1px solid $brandColor;
            }
          }

          .ant-select-selector {
            height: 48px;
            border-radius: 6px;
            display: flex;
            align-items: center;

            .ant-select-focused {
              box-shadow: none !important;
              outline: none;
              border: 1px solid $brandColor;
            }
          }
        }

        .fields-horizontal {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .custom-input-group {
            height: 60px;

            .custom-label {
              width: 100%;
            }

            &:first-child {
              width: calc(100% - 210px);
              margin-right: 10px;
            }

            &:last-child {
              width: 200px;
            }

            .icon {
              position: absolute;
              right: 15px;
              top: 15px;
              width: 18px;
              height: 18px;

              svg {
                width: 100%;
                height: 100%;
                fill: $brandColor;
              }

            }
          }
        }

        .btnsWrapper {
          display: flex;
          margin-top: 10px;
          justify-content: space-between;

          .PrimaryButton {
            width: calc(50% - 5px);
            padding: 0 25px;
          }
        }

      }

      .RightSide {
        width: 40%;

        .result-box {
          width: 100%;
          padding: 22px 20px;
          border: 1px solid #DDDEDF;
          border-radius: 6px;
          margin-top: 46px;

          .result-box-row {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            font-family: Medium;

            .result-box-row-text {
              display: flex;
              align-items: center;
              font-family: Bold;
            }

            svg {
              width: 18px;
              height: 18px;

              path {
                fill: #444;
              }
            }
          }

          .result {
            margin-top: 40px;
            padding: 12px;
            background-color: $brandColor;
            border-radius: 6px;

            .result-box-row {
              font-size: 18px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .results-table {
      width: calc(100% - 2 * #{$mainPadding});
      margin: 0 auto 60px;
      overflow-x: auto;

      table, td, th {
        border: 1px solid #c3c3c3;
      }

      table {
        border-radius: 4px;
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        max-width: 100%;

        thead {
          width: 100%;
          background-color: $brandColor;

          th {
            padding: 10px;
          }
        }

        tbody {
          width: 100%;

          td {
            padding: 4px 10px;
            font-family: Medium;
          }
        }
      }
    }
  }

  @media all and (max-width: 1100px) {
    .ContentWrapper {
      .HeaderWrapper {
        .item-card {
          margin-right: 0;
        }
      }

      .CalcMainContent {
        flex-direction: column;
        padding-bottom: 60px;

        .LeftSide {
          width: 100%;
          margin-bottom: 30px;
        }

        .RightSide {
          width: 100%;

          .result-box {
            margin-top: 25px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1024px) {
    .ContentWrapper {

      .HeaderWrapper {
        padding: 0 $mainPadding;
      }

      .CalcMainContent {
        padding: 40px $mainPadding;
      }
    }
  }

  @media all and (max-width: 600px) {
    .ContentWrapper {

      .CalcMainContent {
        padding-bottom: 30px;

        .LeftSide {
          .calc-title {
            height: 70px;
            flex-direction: column;
          }

          .custom-input-group {
            input {
              padding: 8px 12px;
            }

          }

          .fields-horizontal {
            flex-direction: column;

            .custom-input-group {
              &:last-child {
                width: 100%;
              }

              &:first-child {
                width: 100%;
                margin-right: 0;
              }
            }
          }

          .btnsWrapper {
            .PrimaryButton {
              height: 40px;
              font-size: 14px;
            }
          }
        }

        .fields-horizontal {

          .custom-input-group {
            .ant-select-selector {
              margin-bottom: 10px;
              height: 40px;
            }
          }
        }

        .RightSide {
          .result-box {
            padding: 12px 8px;

            .result-box-row {
              font-size: 14px;
            }

            .result {
              .result-box-row {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

}
