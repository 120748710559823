@import 'mainVariables';
@import 'mixins';

.LayoutWrapper{
  width: 100%;
  height: 100%;
  position: relative;
  .MainContent{
    width: 100%;
    min-height: calc(100vh - 354px);
    @media all and (max-width: 800px) {
      //padding-top: 60px;
    }
  }
  &.mobileMenuOpen {
    overflow: hidden;
  }

  .call-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: fixed;
    bottom: 24px;
    right: calc(#{$mainPadding} / 2);
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $brandColor;
    transition: all 0.2s;

    svg {
      width: 45px;
      height: 45px;
    }

    &::after {
      @include rings(3s, 0s);
    }

    &::before {
      @include rings(3s, 0.5s);
    }

    &:hover{
      background-color: $brandSecColor;
    }
  }


  .call_btn_v2 {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: fixed;
    bottom: 24px;
    right:  20px;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #222222;
    transition: all 0.2s;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (max-width: 550px) {
    .call_btn_v2 {
      right:  16px;
    }
  }
  @media screen and (max-width: 400px) {
    .call_btn_v2 {
      bottom: 95px;
    }
  }
}
