@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.call-modal {
  width: 660px !important;

  &.success {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .ant-modal-header {
      background-color: #70D06C !important;

      .ant-modal-title {
        color: white !important;
      }
    }
  }

  &.error {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x {
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .ant-modal-header {
      background-color: #F0452A !important;

      .ant-modal-title {
        color: white !important;
      }
    }
  }

  &.confirm-btn-disable {
    .ant-modal-footer {
      button {
        &.ant-btn-primary {
          background-color: #EFEFF0 !important;
        }
      }
    }
  }

  .ant-modal-content {
    border-radius: 0;

    .ant-modal-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 38px 36px 0 36px;

      .custom-input-group {
        margin-bottom: 30px;
        width: 100%;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .custom-input-group {
          width: 48%;
          margin-bottom: 40px;
        }

        .input-time {
          width: 100%;
          padding: 4px 0;
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid #D6D6D6;

          &.ant-picker-focused {
            border-color: #8D8D8D;
            outline: 0;
            box-shadow: unset;
          }

          .ant-picker-input > input {
            padding: 5px 0;
            border: 0;
          }

          .custom-input-group {
            textarea {
              border-bottom: 0;
            }
          }
        }
      }

      .time-checkbox-wrapper {
        width: 48%;
        //border-bottom: 1px solid #bfbfbf;
        border: 0;
        margin-bottom: 38px;

        .ant-checkbox-wrapper {
          font-size: 14px;
          font-weight: 500;
          color: #D6D6D6;
          margin-bottom: 8px;
          .ant-checkbox-inner{
            border-radius: 4px;
          }
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: #8D8D8D;
        }
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        height: 58px;
        line-height: 58px;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: black;
          }
        }
      }
    }

    .ant-modal-header {
      height: 58px;
      padding: 0 36px;
      background-color: $brandColor;
      border-bottom: none;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-size: 18px;
        font-family: Medium;
        color: black;
        letter-spacing: 0.5px;
      }
    }

    .ant-modal-footer {
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 40px 40px;

      button {
        padding: 0 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        font-size: 16px;
        border: none;
        text-transform: capitalize;
        transition: all 0.5s ease 0s;
        letter-spacing: 1px;
        white-space: nowrap;
        color: black;
        box-shadow: none;
        border-radius: 4px;

        &:first-child {
          background: #EFEFF0 !important;

          &:hover {
            background: #e2e2e2 !important;
          }
        }

        &.ant-btn-primary {
          background-color: $brandColor;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    width: calc(100% - 80px) !important;
    .ant-modal-content {
      flex-direction: column;

      .ant-modal-body {

        .row {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .custom-input-group {
            width: 100%;
            margin-bottom: 40px;
          }

          .time-checkbox-wrapper {
            width: 100%;
          }
        }
      }

      .custom-input-group {
        width: 100% !important;
      }

      .ant-modal-footer {
        button {
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }
  @media all and (max-width: 450px) {
    width: calc(100% - 20px) !important;
    .ant-modal-content {
      .ant-modal-body {
        .row {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .custom-input-group {
            width: 100%;
            margin-bottom: 40px;
          }

          .time-checkbox-wrapper {
            width: 100%;
          }
        }
      }

      .ant-modal-footer {
        button {
          padding: 0 15px;
        }
      }
    }
    .ant-modal-title {
      width: 74% !important;
    }
  }
}

.ant-picker-ranges {
  display: none;
}

.popup-input-time {
  .ant-picker-time-panel-column {
    .ant-picker-time-panel-cell-disabled {
      display: none;
    }
    .ant-picker-time-panel-cell{
      .ant-picker-time-panel-cell-inner{
        &:hover{
          background: #FFE87C;
        }
      }
    }
  }
}