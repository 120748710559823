@import "../../assets/styles/mainVariables";

.menuWrapper {
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 1002;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.8s;
  padding: 156px 0 116px;
  height: 100vh;
  left: 0;
  transform: translate3d(0, -100vh, 0);
  pointer-events: none;
  overflow: hidden;

  &.opened {
    transform: none;
    pointer-events: all;

    &::before {
      transform: none;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    width: $mainContent;
    margin: 0 auto;

    &__content {
      display: flex;

      &__list {
        display: flex;
        flex-direction: column;
        margin-right: 24%;

        .menu-item {
          font-size: 57px;
          line-height: 57px;
          font-family: Bold;
          letter-spacing: 0.03em;
          margin-bottom: 52px;
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            color: $brandColor;
          }
        }
      }
    }

    &__languages {
      display: flex;

      .language {
        margin-left: 24px;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.03em;
        cursor: pointer;

        &.active {
          //font-weight: 600;
          font-family: Medium;
          color: #222222;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: $mainContent;
    margin: 0 auto;

    &__info {
      display: flex;
      align-items: flex-end;
      width: calc(100% - 200px);
      font-size: 17px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #222222;

      .copyright {
        color: #AAAAAA;
        margin-right: 6%;
        font-size: 14px;
      }

      span, a {
        white-space: nowrap;
        //font-family: Medium;
      }

      .line {
        height: 20px;
        width: 1px;
        margin: 0 30px;
        background-color: #222;
      }
    }

    &__social {
      display: flex;
      flex-direction: column;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.03em;
      color: #484848;
      font-family: Medium;

      a {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    &__main {
      &__content {
        &__list {
          margin-right: 20%;

          .menu-item {
            font-size: 46px;
            line-height: 60px;
            margin-bottom: 42px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 10% $mainPadding;
    &__main {
      &__content {
        flex-direction: column;

        &__list {
          margin-right: 0;

          .menu-item {
            font-size: 42px;
            line-height: 50px;
            margin-bottom: 30px;
          }
        }
      }
    }
    &__footer {
      flex-direction: column;

      &__info {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        align-items: flex-start;
        //width: calc(100% - 200px);
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #222222;

        .copyright {
          width: 100%;
          margin-right: 0;
          font-size: 14px;
          margin-bottom: 20px;
          white-space: break-spaces;
        }
      }

      &__social {
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 17px;
        line-height: 24px;

        a {
          margin-top: 20px;
          margin-right: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    &__main {
      flex-direction: column;

      &__content {
        &__list {
          margin-right: 20%;

          .menu-item {
            font-size: 32px;
            line-height: 44px;
            margin-bottom: 14px;
          }
        }
      }

      &__languages {
        .language {
          margin-left: 24px;
          margin-bottom: 24px;


          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    &__footer {
      flex-direction: column;

      &__info {
        font-size: 14px;
        line-height: 20px;
        .copyright{
        font-size: 12px;
        }

        .line {
          height: 20px;
          margin: 0 10px;
        }
      }
    }
  }

  @media screen and (max-height: 740px) {
    padding: 76px 0 116px;

    &__main {
      &__content {
        &__list {
          margin-right: 20%;

          .menu-item {
            font-size: 32px;
            line-height: 44px;
            margin-bottom: 14px;
          }
        }
      }

      &__languages {
        .language {
          margin-left: 24px;
          margin-bottom: 24px;


          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    &__footer {
      &__info {
        font-size: 14px;
        line-height: 20px;
        .copyright{
        font-size: 12px;
        }

        .line {
          height: 20px;
          margin: 0 10px;
        }
      }

      &__social {
        font-size: 17px;
        line-height: 26px;

        a {
          margin-top: 10px;
        }
      }
    }
  }

}