//@font-face {
//  font-family: Regular;
//  src: url(../fonts/SF-Pro-Text-Regular.otf);
//}
//@font-face {
//  font-family: Medium;
//  src: url(../fonts/SF-Pro-Text-Medium.otf);
//}
//
//@font-face {
//  font-family: Bold;
//  src: url(../fonts/SF-Pro-Text-Bold.otf);
//}
@font-face {
  font-family: Regular;
  src: url(../fonts/Mardoto-Regular.ttf);
}

//@font-face {
//  font-family: Regular;
//  src: url(../fonts/Gilroy-Regular.ttf);
//}

@font-face {
  font-family: Medium;
  src: url(../fonts/Mardoto-Medium.ttf);
}

//@font-face {
//  font-family: Medium;
//  src: url(../fonts/Gilroy-Medium.ttf);
//}

@font-face {
  font-family: Bold;
  src: url(../fonts/Mardoto-Bold.ttf);
}

//@font-face {
//  font-family: Bold;
//  src: url(../fonts/Gilroy-Bold.ttf);
//}

@font-face {
  font-family: Heavy;
  src: url(../fonts/SF-Pro-Text-Heavy.otf);
}

