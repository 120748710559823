@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.image-wrapper {
  width: 100%;
  position: relative;
  //height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 720px;
  //background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .55) 80%, rgba(0, 0, 0, .7) 100%);

  .image-block{
    width: 100% ;
    height: 100%;
    position: relative;
    .main-slider-items {
      position: relative;
      width: 100%;
      height: 100%;



      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #00000033;
        backdrop-filter: blur(17px);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 0 0 20px 20px;
        .overlay-inner{
          max-width: 640px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .overlay-title{
            font-size: 50px;
            font-weight: 400;
            line-height: 55px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #FEFEFE;
          }
          .overlay-description{
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.01em;
            text-align: center;
            margin-top: 20px;
            color: #FEFEFE;
          }
          .overlay-btn{
            margin-top: 20px;
            width: 56px;
            height: 56px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FEFEFE;
          }
        }
      }

      .main-slider-inner-part {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0 0 20px 20px;
        overflow: hidden;
        .slick-current{
          z-index: 1;
        }
      }

      .mobile-block{
        display: none;
      }

    }

    .projects-wrapper {
      display: flex;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 480px;

      .project-item {
        width: 25%;
        position: relative;

        &__inner {
          width: 100%;
          padding-top: 100%;
          position: relative;
          overflow: hidden;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.6s ease-out;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:first-child {
          width: 50%;

          .project-item__inner {
            padding-top: 50%;
          }
        }

        &:not(:last-child) {
          margin-right: 5px;
        }

        .project-item-info {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: black;
          z-index: 10;
          opacity: 0.3;
          padding: 0 70px;
          display: flex;
          align-items: center;
          transition: all 0.6s ease-out;
          overflow: hidden;

          &:hover {
            .content {
              .more {
                svg {
                  transform: rotate(-90deg) translateY(3px);
                }
              }
            }
          }
        }

        .content {
          height: 194px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          top: 50%;
          left: 60px;
          z-index: 20;
          transform: translateY(-50%);
          transition: all 0.2s;
          opacity: 0;

          .title {
            font-family: Bold;
            font-size: 40px;
            line-height: 52px;
            letter-spacing: 0.03em;
          }

          .more {
            font-family: Medium;
            font-size: 19px;
            line-height: 140%;
            letter-spacing: 0.03em;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
              transform: rotate(-90deg);
              transition: transform 0.2s;
            }
          }
        }

        &:hover {
          img {
            transform: scale(1.2);
          }

          .project-item-info {
            opacity: 0.6;
            background-color: $brandColor;
          }

          .content {
            opacity: 1;
          }
        }
      }

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.7;
      }
    }

    .loading {
      filter: blur(10px);
      clip-path: inset(0);
    }

    .loaded {
      filter: blur(0px);
      transition: filter 0.5s linear;
    }
  }

  @media all and (max-width: 1640px) {
    .projects-wrapper {
      height: 400px;
    }
  }

  @media all and (max-width: 1440px) {
    .projects-wrapper {
      height: 360px;

      .project-item {
        .project-item-info {
          padding: 30px;

        }
          .content {
            left: 30px;
            .title {
              font-size: 32px;
              line-height: 48px;
            }
          }
      }
    }
  }
  /*

  @media all and (max-width: 1050px) {
    height: unset;
    .main-slider-items {
      .main-slider-inner-part {
        position: relative;
        width: 100%;
        padding-top: 100%;

        .projects-wrapper {
          position: absolute;
          top: 57px;
          left: 0;
          width: 100%;
          height: 100%;
          transform: unset;
        }
      }
    }
    .projects-wrapper {
      flex-wrap: wrap;
      height: unset;
      position: unset;
      justify-content: space-between;

      .project-item {
        width: calc(50% - 1.5px);

        img {
          width: 100%;
        }

        &:first-child {
          width: 100%;
          margin-bottom: 3px;
        }

        &:not(:last-child) {
          margin-right: 0;
        }

        &:nth-child(2) {
          margin-right: 3px;
        }
      }
    }
  }
  */

  @media all and (max-width: 1050px) {
    height: 100vh;
    .main-slider-items{
      .main-slider-inner-part{
        padding-top: unset;
        .slider-item{
          height: calc(100vh - 56px);

          .image{
            height: 57%;
            width: 100%;
            object-fit: cover;
          }
          .mobile-block{
            width:100%;
            //height:43%;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content:space-around;
            flex-direction: column;
            .blocks-title{
              height: 72px;
              width: 300px;
              font-family:Medium;
              font-size: 28px;
              font-weight: 500;
              line-height: 36px;
              letter-spacing: 0.03em;
              text-align: center;
              color: #222222;
              margin-top: 16px;
              overflow: hidden;
            }
            .about_block{
              margin-top: 5px;
              font-family:Regular;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0.03em;
              text-align: center;
              color: #FADA45;
              text-decoration: underline;
              cursor: pointer;
            }
            .address_block{
              margin-top: 18px;
              font-family:Medium;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0.03em;
              text-align: center;
              color: #222222;
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              cursor: pointer;
              svg{
                margin-right: 10px;
                margin-bottom: 3px;
              }
            }
            .second-block{
              width: 100%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              .PrimaryButton{
                width: 64%;
                height: 40px;
                color: black;
              }
              .icon-block{
                width: 26%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .calc-icon_wrapper{
                  height: 40px;
                  width: 40px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color:#FADA45;
                  cursor: pointer;
                  svg{
                    width: 28px;
                    height: 28px;
                    cursor: pointer;
                    path{
                      fill: black!important;
                    }
                    polygon{
                      fill: black!important;
                    }
                  }
                }
                .icon_wrapper{
                  height: 40px;
                  width: 40px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color:#FADA45;
                  cursor: pointer;
                  svg{
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    path{
                      fill: black!important;
                    }
                    polygon{
                      fill: black!important;
                    }
                  }
                }
              }
            }
            .buttons_block{
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .slider-btn{
                background-color: transparent!important;
              }
            }
          }
        }
      }
    }

    .projects-wrapper {
      .project-item {
        position: unset!important;
        display: none;
        img {
          width: 100%;
        }

        &:first-child {
          width: 100%;

        }

        .project-item-info {
          padding: 30px;

          .content {
            height: 100%;

            .title {
              font-size: 28px;
              line-height: 40px;
            }

            .more {
              font-size: 16px;
            }
          }
        }
      }
    }
    .overlay{
      width: unset!important;
      height: unset!important;
      position: unset!important;
      background-color: unset!important;
    }
    .slick-slider .slider-item img{
      filter: unset!important;
    }
  }

  @media all and (max-width: 400px) {
    .projects-wrapper {
      .project-item {
        .project-item-info {
          padding: 20px;

          .content {
            height: 100%;

            .title {
              font-size: 22px;
              line-height: 32px;
            }

            .more {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

}



