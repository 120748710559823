@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.FloorWrapper {
  //padding-top: $headerHeight;

  .ContentWrapper {

    .HeaderWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: $mainContent;
      margin: auto;
      margin-bottom: 20px;

      .header-left-wrapper {
        margin-top: 20px;

        .header-title {
          font-size: 20px;
          font-family: Bold;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .item-card {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .icon-wrapper {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background-color: #F5F6F7;
          margin-right: 20px;
          transition: .2s;

          &:hover {
            background-color: $brandColor;
          }

          svg {
            path {
              fill: #202020;
            }
          }
        }

        .item-card-content-title {
          color: #BFBCC2;
          font-family: Medium;
          margin-bottom: 2px;
          font-size: 14px;
        }

        .item-card-content-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: Medium;
          font-size: 16px;
        }
      }
    }

    .FloorMainContent {
      display: flex;
      justify-content: space-between;
      width: $mainContent;
      margin: auto;
      padding: calc(#{$mainPadding} / 2) 0 $mainPadding;

      .LeftSide {
        width: 380px;
        display: flex;
        flex-direction: column;

        .floors-main-wrapper {
          display: flex;
          flex-direction: column;
          //align-items: flex-end;

          .floors-content {
            position: relative;
            z-index: 2;
            flex-wrap: nowrap;
            width: 278px;
            box-shadow: 0px 0px 7px 4px #f8f8f8;
            border-radius: 4px;

            .selected-floor {
              font-size: 20px;
              font-family: Bold;
              background-color: $brandColor;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px 4px 0 0;
            }

            .floors-list {
              padding: 20px 15px 30px;

              .list-item {
                width: 50px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: #F5F5F5;
                margin: 6px;
                border-radius: 4px;
                font-size: 18px;
                font-family: Bold;
                cursor: pointer;
                transition: .2s;

                &.active {
                  background-color: $brandColor;
                }

                &.inactive-floor {
                  cursor: default;
                  background-color: $darkGreyColor;
                }

                &:not(.inactive-floor):hover {
                  background-color: $brandColor;
                }
              }
            }
          }

          .parking-wrapper {
            flex-wrap: nowrap;
            width: 278px;
            box-shadow: 0 0 7px 4px #f8f8f8;
            border-radius: 4px;
            margin-top: 16px;
            padding: 12px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .parking-icon {
              width: 50px;
              height: 50px;
            }

            .parking-floors-list {
              display: flex;

              .list-item {
                width: 50px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: #F5F5F5;
                margin: 0 6px;
                border-radius: 4px;
                font-size: 18px;
                font-family: Bold;
                cursor: pointer;
                transition: .2s;

                &.active {
                  background-color: $brandColor;
                }

                &.inactive-floor {
                  cursor: default;
                  background-color: $darkGreyColor;
                }

                &:not(.inactive-floor):hover {
                  background-color: $brandColor;
                }
              }
            }
          }

          .compass-wrapper {
            position: relative;
            z-index: 1;
            margin-left: 40px;
            width: 150px;
            display: flex;
            align-items: flex-end;
            animation: rotate 1.6s ease-in-out;
            animation-iteration-count: 1;

            img {
              width: 100%;
            }

            @keyframes rotate {
              0% {
                transform: rotate(0deg);
              }
              25% {
                transform: rotate(30deg);
              }
              50% {
                transform: rotate(-5deg);
              }
              75% {
                transform: rotate(15deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }
        }

        .badges-wrapper {
          padding: 50px 0;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          //justify-content: space-between;

          .badge-item {
            display: flex;
            white-space: nowrap;
            margin-right: 20px;
            margin-bottom: 20px;

            .badge-color {
              width: 20px;
              height: 20px;
              border-radius: 4px;
              margin-right: 10px;
            }
          }

          .compass-wrapper-in-right {
            display: none;
          }
        }
      }

      .RightSide {
        width: calc(110% - 120px);
        //overflow: hidden;
      }
      .RightSideLastFloor{
        width: calc(110% - 120px);
        .ImageWrapper {
          width: 100%;
          position: relative;
          display: flex;
          align-items: baseline;
          border-radius: 6px 0 0 6px;
          padding-top: 50.8%;


          .sold-item {
            position: absolute;
            width: 50%;
            height: unset;
            top: 50%;
            left: 50%;
            z-index: 103;
            transform: rotate(20deg) translate(-50%, 50%);
          }

          /*   &.ParkingImageWrapper {
               padding-top: 66.1%;
               left: -15%;

               img, svg {
                 width: 130%;
               }

               .sold-item {
                 width: 40%;
                 transform: rotate(20deg) translate(-15%, 50%);
               }
             }*/

          img, svg {
            position: absolute;
            width: 100%;
            height: 108%;
            top: 0;
            left: 0;
            z-index: 0;
          }

          img {
            height: unset;
          }

          svg {
            z-index: 1;

            &:hover polygon, &:hover path, &:hover rect {
              opacity: 1;
              fill: transparent;
              stroke: transparent;
            }

            polygon, path, rect {
              cursor: pointer;
              fill: transparent;
              stroke: transparent;
              transition: .3s;
              animation-iteration-count: 1;
            }
          }

          .tooltip-wrapper {
            display: flex;
            box-shadow: 0 0 3px 2px #f8f8f8;
            border-radius: 4px;


            .tooltip-left-part {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 16px;
              white-space: break-spaces;
              font-family: Medium;
              padding: 10px;
              border-radius: 4px 0 0 4px;
              height: 66px;
              justify-content: center;
              color: white;

              &.without-right-part {
                border-radius: 4px;
                padding: 4px 12px;
                height: 40px;
              }

              .building-number {
                font-size: 15px;
                font-family: Bold;
              }

              .building-text {
                font-size: 16px;
                font-family: Medium;
              }
            }

            .tooltip-right-part {
              display: flex;
              flex-direction: column;
              background: white;
              padding: 10px 20px;
              border-radius: 0 4px 4px 0;
              height: 66px;
              justify-content: center;

              .floors-count {
                font-size: 14px;
                color: #868686;
                font-family: Regular;
              }

              .houses-count {
                font-size: 16px;
                color: #202020;
                font-family: Medium;
              }
            }
          }

          .view-360 {
            position: absolute;
            top: -50px;
            right: 0;
            z-index: 4;
            width: 40px;
            height: 40px;

            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .ParkingImageWrapper{
        padding-top: 71.8%!important;
      }
      .Image{
        padding-top: 67.8%!important;
      }
      .ImageWrapper {
        width: 100%;
        position: relative;
        display: flex;
        align-items: baseline;
        border-radius: 6px 0 0 6px;
        padding-top: 50.8%;


        .sold-item {
          position: absolute;
          width: 50%;
          height: unset;
          top: 50%;
          left: 50%;
          z-index: 103;
          transform: rotate(20deg) translate(-50%, 50%);
        }

     /*   &.ParkingImageWrapper {
          padding-top: 66.1%;
          left: -15%;

          img, svg {
            width: 130%;
          }

          .sold-item {
            width: 40%;
            transform: rotate(20deg) translate(-15%, 50%);
          }
        }*/

        img, svg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 0;
        }

        img {
          height: unset;
        }

        svg {
          z-index: 1;

          &:hover polygon, &:hover path, &:hover rect {
            opacity: 1;
            fill: transparent;
            stroke: transparent;
          }

          polygon, path, rect {
            cursor: pointer;
            fill: transparent;
            stroke: transparent;
            transition: .3s;
            animation-iteration-count: 1;
          }

          path[id="disabled"] {
            fill: #6D6E71;
            stroke: #6D6E71;
           }

        }

        .tooltip-wrapper {
          display: flex;
          box-shadow: 0 0 3px 2px #f8f8f8;
          border-radius: 4px;


          .tooltip-left-part {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            white-space: break-spaces;
            font-family: Medium;
            padding: 10px;
            border-radius: 4px 0 0 4px;
            height: 66px;
            justify-content: center;
            color: white;

            &.without-right-part {
              border-radius: 4px;
              padding: 4px 12px;
              height: 40px;
            }

            .building-number {
              font-size: 15px;
              font-family: Bold;
            }

            .building-text {
              font-size: 16px;
              font-family: Medium;
            }
          }

          .tooltip-right-part {
            display: flex;
            flex-direction: column;
            background: white;
            padding: 10px 20px;
            border-radius: 0 4px 4px 0;
            height: 66px;
            justify-content: center;

            .floors-count {
              font-size: 14px;
              color: #868686;
              font-family: Regular;
            }

            .houses-count {
              font-size: 16px;
              color: #202020;
              font-family: Medium;
            }
          }
        }

        .view-360 {
          position: absolute;
          top: -50px;
          right: 0;
          z-index: 4;
          width: 40px;
          height: 40px;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media all and (max-width: 1400px) and (min-width: 1025px) {
    .ContentWrapper {
      .FloorMainContent {
        .LeftSide {
          .floors-main-wrapper {

            .floors-content {
              width: 220px;

              .floors-list {
                padding: 10px 10px 20px;

                .list-item {
                  font-size: 17px;
                  margin: 5px;
                  width: 40px;
                  height: 40px;
                }
              }
            }

            .parking-wrapper {
              width: 220px;
              padding: 6px 10px;
              margin-top: 8px;

              .parking-icon {
                width: 40px;
                height: 40px;
              }

              .parking-floors-list {

                .list-item {
                  font-size: 17px;
                  margin: 5px;
                  width: 40px;
                  height: 40px;
                }
              }
            }

            .compass-wrapper {
              margin-left: 20px;
              width: 130px;
            }

          }

          .badges-wrapper {
            padding: 30px 0;

            .badge-item {
              font-size: 14px;
              font-family: Regular;
            }
          }
        }

        .RightSide {
          width: calc(110% - 100px);
        }
      }
    }
  }

  @media all and (max-width: 1024px) {
    .ContentWrapper {
      .HeaderWrapper {
        padding: 0 $mainPadding;
      }

      .FloorMainContent {
        flex-direction: column;
        padding: 0 $mainPadding;

        .LeftSide {
          flex-direction: row;
          margin-bottom: 30px;

          .badges-wrapper {
            padding: 0 0 0 30px;
            flex-direction: column;
            justify-content: flex-start;

            .badge-item {
              margin-bottom: 20px;
            }
          }
        }

        .RightSide {
          width: 100%;
        }
      }
    }
  }

  @media all and (max-width: 800px) {
    .ContentWrapper {


      .FloorMainContent {

        .LeftSide {
          .floors-main-wrapper {

            .floors-content {
              width: 220px;

              .floors-list {
                padding: 10px 10px 20px;

                .list-item {
                  font-size: 17px;
                  margin: 5px;
                  width: 40px;
                  height: 40px;
                }
              }
            }

            .parking-wrapper {
              width: 220px;
              padding: 6px 10px;
              margin-top: 8px;

              .parking-icon {
                width: 40px;
                height: 40px;
              }

              .parking-floors-list {

                .list-item {
                  font-size: 17px;
                  margin: 5px;
                  width: 40px;
                  height: 40px;
                }
              }
            }

            .compass-wrapper {
              margin-left: 20px;
              width: 130px;
            }

          }

          .badges-wrapper {
            padding-left: 15px;

            .badge-item {
              font-size: 13px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .ContentWrapper {

      .FloorMainContent {

        .LeftSide {

          .floors-main-wrapper {
            .compass-wrapper-in-left {
              display: none;
            }
          }

          .badges-wrapper {
            padding-left: 20px;

            .compass-wrapper {
              width: 140px;
              display: flex;
              align-items: flex-end;

              img {
                width: 100%;
              }

              &.compass-wrapper-in-right {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 450px) {
    .ContentWrapper {

      .FloorMainContent {

        .LeftSide {
          width: 100%;
          flex-direction: column;
          margin-bottom: 0;

          .floors-main-wrapper {
            .floors-content {
              width: 100%;

              .floors-list {
                padding: 10px 10px 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              }
            }

            .parking-wrapper {
              width: 100%;
            }

            .compass-wrapper {
              display: none;
            }
          }

          .badges-wrapper {
            padding: 30px 0 10px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .badge-item {
              margin-bottom: 20px;

              .badge-color {
                margin-right: 4px;
              }
            }

            .compass-wrapper {

              &.compass-wrapper-in-right {
                display: none;
              }
            }
          }
        }

        .RightSide{
          .ImageWrapper{
            margin: 30px 0;
          }
        }
      }
    }
  }

}
