.Stories {
  //padding-top: 80px;
  width: 100%;
  height: 100vh;
  position: relative;
  .iframe-wrapper{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    iframe{
      height: 100%;
      width: 100%;
    }
    .abs{
      position: absolute;
      left: 20px;

      top: 100px;
      .PrimaryButton{
        margin-top: 10px;
       width: 150px;
      }
    }
  }
  .right-sidebar-viewer-controls{
    display: none;
  }
}
