@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/fonts';

.PrimaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #222222;
  border-radius: 6px;
  padding: 0 32px;
  border: none;
  text-transform: capitalize;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: $brandColor;
  font-family: Medium;
  white-space: nowrap;

  &.dark {
    color: #202020;
    background: #EFEFF0 !important;
    //&:hover {
    //  background: $brandColor !important;
    //}
  }

  &.bordered {
    color: #202020;
    background: inherit !important;
    border: 1px solid #202020;
  }

  &.hover-bg-change {
    &:hover {
      &:not(:disabled) {
        background-color: $brandColor !important;
        color: white;
      }
    }
  }

  &.bg-white {
    background-color: white;
    color: $brandColor;
    border: 1px solid $brandColor;

  }

  &.bg-grey {
    background-color: #F5F4F2;
  }

  &:disabled {
    color: #202020;
    cursor: not-allowed;
    background: #f5f5f5;
  }

  &:hover{
    background-color: $brandSecColor;
  }

  .flex-row {
    display: flex;
    align-items: center;

    .ant-spin {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    height: 34px;
  }
}

.arrows {
  display: flex;

  .slider-btn {
    width: 50px;
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    border-radius: 4px;

    img, svg {
      width: 16px;
      height: 16px;

      path {
        fill: black;
      }
    }

    &:hover {
      background-color: $brandColor;
    }
  }

  .disabled {
    visibility: hidden;
  }
}
