@import 'mainVariables';
@import 'mixins';

@include custom-slider();

.ant-select {
  border-bottom: 1px solid $greyBorderColor !important;
  transition: all 0.2s;

  &.ant-select-single {
    //margin-bottom: 10px;
    border: none;
  }

  &.invalid .ant-select-selection {

    border-color: #FF0000;
  }

  .ant-select-selection__placeholder {
    color: #9b9b9b;
    font-size: 13px;
  }

  .ant-select-selection--single {
    height: 35px;

    .ant-select-selection__rendered {
      line-height: 33px;

      &:focus, &:hover, &:active {
        outline: none;
      }
    }
  }

  .ant-select-search input {
    outline: none !important;
    padding: 6px 0;
  }

  .ant-select-selector {
    border: 0 !important;
    padding: 0 !important;
    //border-color: white;
    .ant-select-selection-placeholder {
      color: #484848;
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      //border: 0 !important;
      box-shadow: 0 0 0 0 white !important;
    }
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 3px #D0E3F3;

}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    background-color: $bgGreyColor;
  }
}

.ant-select-dropdown-menu-item {
  background-color: white;

  &:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }

  &:hover {
    background-color: rgba(179, 179, 179, 0.38) !important;
  }
}

.ant-select-dropdown-menu-item-active {
  background-color: rgba(255, 255, 255, 0.38) !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}

.ant-calendar-date {
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background-color: #FF0000;
  }
}

.ant-popover-content {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 8px;
    border: 1px solid #DDDDDD;

    .ant-popover-inner-content {
      padding: 0;

    }
  }
}

.ant-popover-placement-bottom {
  padding-top: 5px;
}

.ant-popover-custom-content {
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  color: #202020;
  font-family: Helvetica;
}

.ant-dropdown-trigger {
  display: flex;
  align-items: center;

  &.upload-btn {
    padding-right: 0;
  }

  @media all and (max-width: 1000px) {
    padding: 0px;
  }
}

.ant-modal {
  width: 50%;
  transform-origin: unset !important;
  animation-duration: unset !important;
}

.ant-breadcrumb {
  a {
    &:hover {
      color: $brandColor;
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: $brandColor;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $brandColor;
}

.ant-radio-wrapper {

}

.ant-radio-wrapper {
  white-space: normal;

  .ant-radio-inner {
    width: 18px;
    height: 18px;

    &::after {
      width: 18px;
      height: 18px;
      margin-top: -9px;
      margin-left: -9px;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: $brandColor;
  }

  .ant-radio-checked {

    .ant-radio-inner {
      border-color: $brandColor !important;

      &::after {
        background-color: $brandColor !important;
      }
    }

    &::after {
      border: 1px solid $brandColor !important;
    }
  }
}

.ant-select-dropdown {
  padding: 16px;
}

.ant-checkbox-group {
  width: 100%;
}

.ant-checkbox-group-item {
  display: flex !important;
  align-items: center !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $brandColor !important;
}

.ant-checkbox-wrapper {
  display: flex !important;
  align-items: center !important;
  height: 40px;
  margin: 0 !important;
  position: unset !important;

  .ant-checkbox {
    margin-bottom: 10px !important;

    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }

  }

  span:not(.ant-checkbox) {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.06em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-checkbox:hover {
    &:after {
      border-color: transparent;
    }
  }

  &:hover {
    .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $brandColor;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: rgba(255, 213, 18, 0.4);
      border: 1px solid $brandColor;

      &:after {
        //border: 1px solid black;
        border-color: black;
        border-width: 1px;
      }
    }

    &:after {
      border-color: transparent;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    //border-color: black;
  }
}
