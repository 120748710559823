@import "../../assets/styles/mainVariables";
@import "../../assets/styles/mixins";

.right-menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9901;
  width: 100%;
  height: 100vh;
  overflow: auto;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;

  .right-menu-content {
    width: 450px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px;
    z-index: 9002;
    height: 100%;
    background-color: white;
    overflow-y: auto;


    &::-webkit-scrollbar {
      width: 0;
    }

    nav {
      width: 100%;
      flex-direction: column;
      margin-top: 20px;
      position: relative;

      .clear-icon {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }

      .nav-item {
        font-size: 18px;
        cursor: pointer;
        font-family: Medium;
        padding: 8px 10px;
        width: fit-content;

        &:hover {
          color: $brandColor;
        }
      }
    }

    .menu-languages{
      display: flex;
      padding: 20px 10px;
      .language{
        margin-right: 10px;
        cursor: pointer;
        font-size: 16px;

        &.active {
          font-family: Medium;
          color: #222222;
        }
      }
    }

    @media all and (max-width: 1024px) {
      width: 100%;
    }
  }

  .menu-overlay {
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9000;
    opacity: 0.55;
  }

  &.expand_on {
    visibility: visible;
    opacity: 1;
  }

  & {
    .hide {
      display: none;
    }
  }
}

.ant-dropdown {
  z-index: 10003;
}


@media all and (max-width: 600px) {
  .right-menu-wrapper {
    padding-top: 60px;

    .right-menu-content {
      padding: 0 $mainPadding $mainPadding;
      width: 100%;

      .search_box {
        height: 36px;
        padding: 5px;

        .input-group-append {
          .search_btn {
            padding-right: 5px;

            svg {
              width: 20px;
            }
          }
        }

        .search {
          font-size: 13px;
        }
      }

      .ant-menu {
        margin-top: 5px;
      }
    }
  }
}