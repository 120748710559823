@import "../mainVariables";

.headerWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10000;

  &.is_base_header {
    height: $headerHeight;
    background: $darkColor;
  }

  &.is_home_header {
    height: 96px;
    background: white;
  }

  .menu-wrapper {
    width: $mainContent;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  .left-side {
    width: 50%;
    display: flex;
    align-items: center;

    .logo-wrapper {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 14%;

      img {
        width: 170px;
        height: 100%;
      }

      svg {
        width: 170px;
        height: 100%;
      }
    }
  }

  .right-side {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .header-phone {
      height: 40px;
      color: white;
      border-radius: 4px;
      border-bottom: none;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 16px;

      &__icon {
        margin-left: 20px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: $brandColor;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;

        &:hover {
          background-color: $brandSecColor;
        }
      }


      span {
        margin-top: 2px;
      }
    }

    .language-menu {
      color: white;
      margin-left: 94px;
      cursor: pointer;
      font-family: Regular;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 32px;
      background-color: transparent;
      transition: background-color 0.2s;
      border-radius: 4px;

      img {
        margin-right: 0;
        width: 26px;
        height: 16px;
        object-fit: cover;
      }
    }
  }

  &__burger_wrapper {
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brandColor;
    position: relative;
    cursor: pointer;
    margin-left: 37px;
    top: 0;

    &:hover {
      &__burger::after {
        width: 38px;
      }
    }
  }

  &__burger {
    position: relative;
    left: -4px;
    width: 25px;
    height: 19px;
    transition: all .3s cubic-bezier(.785, .135, .15, .86);

    &.is-active {
      left: unset;
      right: calc((100% - #{$mainContent}) / 2 + 26px);
      z-index: 1010;
      position: fixed;
    }
  }

  &__burger::after, &__burger::before {
    content: "";
    position: absolute;
    left: 0;
    width: 35px;
    height: 3px;
    overflow: hidden;
    background: #1D2135;
    transition: width .3s cubic-bezier(.785, .135, .15, .86), background .3s cubic-bezier(.785, .135, .15, .86), transform .3s cubic-bezier(.785, .135, .15, .86);
  }

  &__burger::before {
    top: 0;
  }

  &__burger::after {
    top: 12px;
    width: 27px;
  }

  &__burger.is-active::before {
    transform: rotate(45deg) translate3d(0, 8.5px, 0);
  }

  &__burger.is-active::after {
    width: 38px;
    -webkit-transform: rotate(-45deg) translate3d(0, -8.5px, 0);
    transform: rotate(-45deg) translate3d(0, -8.5px, 0);
  }

  &__burger:hover::after {
    width: 38px;
  }

  &.fullscreen-menu {
    .menuWrapper::before {
      transform: none;
    }
  }

  &.is_home_header {
    .right-side {
      .header-phone {
        color: #222222;
      }

      .language-menu {
        background-color: white;

        &:hover {
          background-color: #F5F5F5;
        }
      }
    }

    .headerWrapper__burger_wrapper {
      align-self: flex-start;
    }
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    margin: unset;
    height: 56px;
    padding-left: $mainPadding;
    background-color: $darkColor;

    &.dark {
      height: unset;
    }

    .left-side {

      .logo-wrapper {
        margin-right: 0;
        height: 28px;

        img, svg {
          width: auto;
        }
      }
    }
    .right-side {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .header-phone, {
        display: none;
      }

      .language-menu {
        margin-left: 40px;
      }
    }
    &__burger_wrapper {
      width: 56px;
      height: 56px;
    }
    &__burger {
      width: 30px;
      height: 19px;
    }
  }

  @media screen and (max-width: 700px) {
    padding-left: 0;
    .left-side {
      padding-left: 20px;

      .PrimaryButton {
        display: none;
      }
    }
    .right-side {
      .language-menu {
      }
    }
    &__burger_wrapper {
      margin-left: 24px;
    }
    &.is_home_header {
      height: $headerHeight;
    }

  }
}
