@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.AboutProject {
  width: $mainContent;
  position: relative;
  margin: auto;
  margin-bottom: 100px;

  .blur-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    z-index: 1;
    background-color: $brandColor;
    filter: blur(70px);
    transform: translate(-50%, -50%);

    &.right {
      right: 0;
      top: 0;
      left: unset;
      transform: translate(0%, -100%);
    }
  }

  &:last-child {
    .blur-circle {
      display: none;
    }
  }

  .project-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;


    .project-info {
      width: $halfContent;

      &__mobile_title{
        display: none;
        font-family: Medium;
        font-weight: 500;
        font-size: 28px;
        line-height: 140%;
        letter-spacing: 0.03em;
        margin-bottom: 50px;
      }

      &__title {
        font-family: Medium;
        font-weight: 500;
        font-size: 28px;
        line-height: 140%;
        letter-spacing: 0.03em;
        margin-bottom: 50px;
      }

      &__description {
        font-weight: 400;
        font-size: 19px;
        line-height: 140%;
        letter-spacing: 0.03em;
        margin-bottom: 50px;
      }

      &__address {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.03em;
        color: #AAAAAA;
        margin-bottom: 80px;
        cursor: pointer;

        svg {
          margin-right: 7px;

          path {
            fill: #8D8D8D;
          }
        }
      }

      &__question {
        display: flex;
        align-items: center;

        .PrimaryButton {
          margin-right: 50px;
        }

        .link {
          font-weight: 400;
          font-size: 19px;
          line-height: 140%;
          letter-spacing: 0.03em;
          color: #484848;

          a {
            font-family: Medium;
            font-size: 19px;
            line-height: 140%;
            letter-spacing: 0.03em;
            text-decoration-line: underline;
            color: #222222;
          }
        }
      }
    }
    .project-link{
      position: relative;
      width: $halfContent;
      height: 430px;
      cursor: pointer;
      overflow: hidden;
      .project-img-wrapper {
        width: 100%;
        height: 100%;
        .project-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .overlay-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: black;
          opacity: 0;
          transition: all .2s;
        }

        .overlay-title {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          color: white;
          font-size: 40px;
          font-family: Bold;
          opacity: 0;
          transition: all .2s;
        }

        &:hover{
          .overlay-wrapper{
            opacity: 0.6;
          }
          .overlay-title{
            opacity: 1;
          }
        }
      }
    }

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  @media all and (max-width: 1024px) {
    .project-content {
      .project-info {
        width: 100%;
        margin-bottom: 60px;
      }
      .project-link{
        width: 100%;
        height: unset;
        .project-img-wrapper {
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .project-content {
      flex-direction: column-reverse;

      &.reverse{
        flex-direction: column-reverse!important;
      }

      .project-info {
        width: 100%;
        margin-bottom: 42px;

        &__mobile_title{
          display: flex;
        }

        &__title {
          display: none;
        }

        &__description {
          margin-bottom: 25px;
          margin-top: 30px;
        }

        &__address {
          margin-bottom: 27px;
        }

        &__question {
          .PrimaryButton {
            margin-right: 20px;
          }

          .link {
            font-size: 14px;

            a {
              font-size: 14px;
            }
          }
        }
      }

      .project-img-wrapper {
        .overlay-title{
          font-size: 30px;
        }
      }
    }
  }
}