@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mixins";

.home-filter-wrapper {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 80px;
  right: 80px;
  height: 90px;
  transform: translateY(45px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  background-color: white;
  border-radius: 12px;
  width: calc(100% - 160px);

  .clear-filters-wrapper {
    position: absolute;
    bottom: -48px;
    z-index: 3;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8D8D8D;
    cursor: pointer;
    left: 0;
    padding: 0 16px;
    transition: color 0.2s;
    font-size: 16px;
    font-weight: 300;
    font-family: Medium;
    letter-spacing: 0.03em;

    svg {
      margin-left: 6px;

      path {
        transition: all 0.2s;
      }
    }

    &:hover {
      color: #EC4B1E;

      svg {
        margin-left: 6px;

        path {
          fill: #EC4B1E;
        }
      }
    }
  }

  .PrimaryButton {
    width: 190px;
  }

  .custom-input-group {
    width: 16%;

    .ant-select-selection-item {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #222222;
    }

    .ant-select-selection-placeholder {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #222222;
    }
  }

  .slider-filter {
    width: 25%;
  }

  .slider-filter-actions {
    display: flex;
    align-items: center;

    .calc_wrapper {
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #f5f6f7;
      margin-right: 8px;
      margin-left:  8px;
      transition: .2s;
      cursor: pointer;
      svg {
        width: 24px;

        path {
          fill: black;
        }
      }

      &:hover {
        background-color: $brandColor;
      }
    }

    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 190px;
      font-size: 14px;
      line-height: 14px;
      color: #000;
      border-radius: 4px;
      padding: 0 17px;
      border: none;
      text-transform: capitalize;
      transition: all .5s ease 0s;
      background-color: #ffdf5b;
      font-family: Medium;
      white-space: nowrap;
      letter-spacing: .03em;
    }
  }


  @media all and (max-width: 1500px) {
    padding: 0 20px;
    .custom-input-group {
      width: 15%;
    }
    .slider-filter-actions {
      .calc_wrapper {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    display: none;
  }
  @media all and (max-width: 600px) {
    height: unset;
    width: 100%;
    flex-wrap: wrap;
    left: unset;
    border-radius: 0;
    padding: 30px $mainPadding;
    .custom-input-group {
      width: 48%;
      margin-right: 4%;

      &:nth-child(3) {
        margin-right: 0;
      }

      &:nth-child(4) {
        width: 100%;
        margin-top: 25px;
      }
    }
    .slider-filter {
      width: 100%
    }

    .slider-filter-actions {
      .calc_wrapper {
        margin: 25px 16px 0 0;
      }
    }
  }

}


.search-panel {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: all 0.4s;
visibility: hidden;
  &__overlay {
    background-color: black;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s;

    &.visible {
      opacity: 0.4;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    transform: translatex(calc(-100% - 20px));
    padding: 0 50px;
    background-color: $brandColor;
    position: absolute;
    bottom: 20vh;
    left: 90px;
    z-index: 10;
    transition: all 0.4s;
  }

  &.open_panel {
    z-index: 100;
    visibility: visible;
    .search-panel__wrapper {
      transform: translatex(0);
    }
  }

  .PrimaryButton {
    border: 1px solid black;
  }

  .custom-input-group {
    width: 230px;
    margin-right: 25px;

    .ant-select {
      border-bottom: 1px solid #656565 !important;

      .ant-select-selector {
        .ant-select-selection-placeholder {
          color: #656565;
        }
      }

      .ant-select-arrow {
        svg {
          path {
            fill: #656565;
          }
        }
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
    }
  }

  .slider-filter {
    width: 230px;
    margin-right: 25px;

    .ant-slider {
      .ant-slider-handle {
        background-color: $brandColor;

      }
    }
  }

  .close-panel {
    display: none;
  }

  @media all and (max-width: 1024px) {

    &__wrapper {
      display: flex;
      justify-content: unset;
      align-items: unset;
      flex-direction: column;
      height: 100vh;
      width: 100%;
      //transform: unset;
      padding: 20% 50px;
      background-color: $brandColor;
      position: fixed;
      bottom: unset;
      top: 0;
      left: 0;
      z-index: 1000;
      transition: all 0.4s;
    }

    &.open_panel {
      z-index: 1000;
    }

    .custom-input-group {
      width: 100%;
      margin: 0 0 34px 0;
    }

    .slider-filter {
      width: 100%;
      margin: 0 0 25px 0;
    }

    .PrimaryButton {
      margin-top: 25px;
    }

    .close-panel {
      display: block;
      position: absolute;
      top: 20px;
      right: 50px;
      cursor: pointer;

      polygon {
        fill: black;
      }
    }
  }

  @media all and (max-width: 400px) {
    &__wrapper {
      padding: 20% 20px;
    }

    .close-panel {
      top: 20px;
      right: 20px;
    }
  }

  @media screen and (max-width: 700px) {
    height: 130vh;
  }
}


.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: $greyBorderColor !important;
}

//.slick-slider {
//  height: 100%;
//
//  .slick-list {
//    padding: 0;
//  }
//
//  .slider-item {
//
//    img {
//      width: 100%;
//      height: 720px;
//      object-fit: cover;
//    }
//
//    .slider-info-wrapper {
//      position: absolute;
//      top: 0;
//      left: $mainPadding;
//      height: 100%;
//      width: 100%;
//      display: flex;
//
//      .info-content {
//        display: flex;
//        justify-content: space-around;
//        flex-direction: column;
//        height: 45%;
//        position: relative;
//        margin-top: 7%;
//        width: 100%;
//      }
//
//      .slide-title {
//        font-family: Bold;
//        font-size: 62px;
//        overflow: hidden;
//        line-height: 90px;
//        width: 50%;
//        height: 176px;
//      }
//
//      .slide-sub-title {
//        font-family: Regular;
//        font-size: 20px;
//        line-height: 34px;
//        width: 50%;
//        height: 122px;
//        overflow: hidden;
//        letter-spacing: 0.4px;
//      }
//
//      .link-button {
//        width: 218px;
//        height: 60px;
//      }
//    }
//  }
//}


