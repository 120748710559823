@import '../../../assets/styles/mainVariables';
@import '../../../assets/styles/fonts';

.apartments-card {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 35px 25px 10px;
  border-bottom: 2px solid $bgGreyColor;
  transition: all .3s ease-in-out;
  display: flex;
  //justify-content: space-between;

  &__viewed {
    position: absolute;
    left: 0;
    bottom: 12px;
    height: 18px;
    z-index: 10;
    background-color: #FFD42626;
    display: flex;
    align-items: center;
    padding: 0 10px 0 7px;

    svg {
      margin-right: 2px;
    }

    span {
      color: #232D35;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      margin-top: 2px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    width: calc(100% - 310px);
    max-width: 360px;
    padding: 0 60px 0 25px;

    &__number {
      font-family: Medium;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.03em;
      color: #222222;
      margin-bottom: 20px;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.03em;

      .item-name {
        color: #8D8D8D;;
        margin-right: 4px;
      }

      .line {
        flex-grow: 1;
        border-top: 1px dotted #BFBCC2;
      }

      .item-area {
        color: #484848;
        margin-left: 4px;
      }
    }
  }

  &__actions {
    width: 174px;
    margin-left: auto;

    .is-reserve {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #F9A933;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 28px;
    }

    .card-price {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #222222;
      margin-bottom: 30px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      svg {
        width: 16px;
        height: 16px;
        margin-top: -3px;
      }
    }

    .card-btns-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .PrimaryButton {
        width: calc(100% - 45px);
        height: 36px;
        font-size: 14px;

        &.upload-btn {
          background-color: #f5f5f5;
          padding: 0;
          width: 36px;
          color: #202020;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .apartments-building {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Bold;
    border-radius: 4px;
    height: 28px;
    width: 28px;
    cursor: pointer;
    background-color: #f5f5f5;
    transition: all .3s ease-in-out;
  }

  .apartments-number {
    font-size: 16px;
    font-family: Bold;
    color: #F83636;
  }

  .areas-list {
    margin-bottom: 20px;

    .list-item {
      display: flex;
      align-items: center;
      font-size: 15px;
      margin-bottom: 4px;

      .item-name {
        margin-right: 4px;
      }

      .line {
        flex-grow: 1;
        border-top: 1px dotted #BFBCC2;
      }

      .item-area {
        margin-left: 4px;
        font-family: Medium;
        min-width: 42px;
      }
    }
  }

  &:hover {
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
    border-color: transparent;
  }

  &:hover .apartments-building {
    background-color: $brandColor;
  }

  @media all and (max-width: 1300px) {
    &__wrapper {
      width: 100px;
      height: 100px;
    }

    &__content {
      width: calc(100% - 240px);
      padding: 0 30px 0 15px;

      &__number {
        font-size: 15px;
        line-height: 28px;
        margin-bottom: 14px;
      }

      &__item {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__actions {
      width: 134px;

      .is-reserve {
        margin-bottom: 10px;
      }

      .card-price {
        margin-bottom: 14px;
      }

      .card-btns-wrapper {

        .PrimaryButton {
          width: 86%;
          height: 36px;
          font-size: 13px;

          &.upload-btn {
            background-color: #f5f5f5;
            padding: 0;
            width: 36px;
            color: #202020;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 480px) {
    flex-wrap: wrap;

    &__wrapper {
      width: 80%;
      height: 100%;
      margin: auto;
      margin-bottom: 10px;
    }

    &__content {
      width: calc(100% - 134px);
      padding: 0 30px 0 0;

      &__item {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

}
