
:root {
  --brandColor: #FFDF5B;
  --brandSecColor: #FFE87C;
  --mainPadding: 7.5%;
  --mainContent: 1670px;
  --halfContent: calc(50% - 100px);
  --headerHeight: 72px;
  --elementMainHeight: 34px;
}

$mainPadding: var(--mainPadding);
$mainContent: var(--mainContent);
$halfContent: var(--halfContent);
$headerHeight: var(--headerHeight);
$elementMainHeight: var(--elementMainHeight);

//--------Colors---------

$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$darkColor: #232D35;
$greyColor: #F4F4F4;
$bgGreyColor: #F5F4F2;
$darkGreyColor: #E2E2E2;
$greyBorderColor: #bfbfbf;
$textColor: #282828;
$greyTextColor: #202020;
$redColor: #ef5151;


@media (max-width: 1680px) {
  :root {
    --mainContent: 1400px;
  }
}

@media (max-width: 1380px) {
  :root {
    --mainContent: 1200px;
    --halfContent: calc(50% - 60px);
  }
}
@media (max-width: 1280px) {
  :root {
    --mainContent: 1000px;
  }
}
@media (max-width: 1180px) {
  :root {
    --mainContent: 1000px;
  }
}
@media (max-width: 1024px) {
  :root {
    --headerHeight: 56px;
    --mainContent: 100%;
  }
}

@media (max-width: 800px) {
  :root {
    --mainPadding: 6%;
  }
}

@media (max-width: 550px) {
  :root {
    --mainPadding: 20px;
  }
}
