@import "../../assets/styles/mainVariables";

.Homepage {
  width: 100%;
  height: 100%;
  position: relative;
  //background: rgba(245, 244, 242, 1);
  z-index: 100;
  .loader-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: white;
  }

  .main_slider_wrapper {
    width: $mainContent;
    margin: auto;
    position: relative;
  }


  .search-panel-mobile {
    display: flex;
    align-items: center;
    padding: 8px;
    z-index: 101;
    position: fixed;
    bottom: 24px;
    left: 20px;
    border-radius: 14px;
    background-color: #222222;

    .panel_filter_button{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin-right: 8px;
      background: #FFFFFF33;
      transition: all 0.2s;
      &:hover{
        background-color:#FFFFFF66;
      }

    }
    .search_from_panel{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0 16px;
      width: unset;
      border-radius: 8px;
      background: $brandColor;
      font-size: 14px;
      font-weight: 400;
      margin-left: 8px;
      transition: all 0.2s;
      &:hover{
        background: #eddfa3;
      }
    }
  }

  .search-panel-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    z-index: 101;
    position: fixed;
    bottom: 20vh;
    left: 0;
    background-color: $brandColor;
    transition: all 0.2s;

    &:hover{
      background-color: $brandSecColor;
    }

    svg{
      width: 34px;
      height: 34px;
      polygon{
        fill: black;
      }
    }
  }
  @media screen and (max-width: 700px) {
    img {
      max-width: unset !important;
      max-height: unset !important;
    }
  }

  @media screen and (max-width: 550px) {
    .search-panel-mobile {
      left: 16px;
    }
  }
  @media screen and (max-width: 400px) {
    .search-panel-mobile {
      right: 16px;
      .search_from_panel{
        flex: 1;
      }
    }
  }
}
