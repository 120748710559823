@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/mixins";

.map_window {
  width: 100vw;
  height: calc(100vh);
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0;
  transition: all 0.3s;
  z-index: -1;
  &.show-map {
    opacity: 1;
    z-index: 1000;
  }

  &__header {
    height: 72px;
    width: 100%;
    padding: 0 calc((100% - #{$mainContent}) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    background-color: $darkColor;
    position: relative;

    img{
      height: 32px;
    }
    .close{
      position: relative;
      transition: all .3s cubic-bezier(.785, .135, .15, .86);
      cursor: pointer;
      right: 40px;

      &::after, &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 38px;
        height: 4px;
        overflow: hidden;
        background: white;
      }
      &::before {
        transform: rotate(45deg) translate3d(0, 0px, 0);
      }
      &::after {
        transform: rotate(-45deg) translate3d(0, -1px, 0);
      }
    }

    svg {
      width: 68px;
      height: 68px;
      cursor: pointer;
      polygon{
        fill: white;
      }
    }
  }

  &__zoom {
    position: absolute;
    z-index: 101;
    top: 50%;
    right: 40px;

    button {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: $brandColor;
      color: black;
      font-size: 36px;
      line-height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      svg{
        width: 34px;
        height: 34px;
      }
    }
    @media screen and (max-width: 700px){
      top: 76%;
      right: 24px;
    }
  }

  .icon-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    transform: translate(0%, calc(-100% - 32px));

    &:after, &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    &:before {
      transition: opacity .1s ease;
      height: 26px;
      width: 2px;
      background-color: $brandColor;
    }

    &:after {
      transition: width, height, margin-top, border-color 0.1s ease;
      margin-top: 26px;
      width: 8px;
      height: 8px;
      border-color: transparent;
      border-radius: 50%;
      background-color: $brandColor;
    }
  }

  .marker {
    //width: 81px;
    //height: 81px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //border: 2px solid $brandColor;
    //border-radius: 50%;

    img {
      width: 67px;
      height: 67px;
      border: 2px solid white;
      border-radius: 50%;
      transition: all .1s ease;
      background-color: #ddd;
    }
  }

  .marker-title {
    width: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: opacity .1s ease;
    font-size: 12px;
    border-radius: 4px;
    background-color: $brandColor;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    padding: 5px 10px;
  }

  //@media all and (max-width: 1400px) {
  //  width: 350px;
  //}
  //
  //@media all and (max-width: 1200px) {
  //  width: 300px;
  //}
  //
  //@media all and (max-width: 1080px) {
  //  display: none;
  //}
}
