@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";
.disabled-link {
  pointer-events: none;
}
.BuildingsWrapper {
  //padding-top: $headerHeight;
  overflow: hidden;

  .TopContent {

    .AddressWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: $mainContent;
      margin: 0 auto 20px;

      .address-left-wrapper {
        margin-top: 20px;

        .address-title {
          font-size: 20px;
          text-transform: uppercase;
          font-family: Bold;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .address-right-wrapper {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .parking-wrapper {
          height: 50px;
          margin-right: 20px;

          img, svg {
            height: 100%;
            border-radius: 2px;

            path {
              transition: .2s;
            }

            &:hover {
              path {
                fill: $brandColor
              }
            }
          }
        }
      }

      .item-card {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon-wrapper {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background-color: #F5F6F7;
          margin-right: 20px;
          transition: .2s;
          cursor: pointer;

          &:hover {
            background-color: $brandColor;
          }

          svg {
            path {
              fill: #202020;
            }
          }
        }

        .item-card-content-title {
          color: #BFBCC2;
          font-family: Medium;
          margin-bottom: 2px;
          font-size: 14px;
        }

        .item-card-content-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: Medium;
          font-size: 16px;
        }
      }
    }

    .ImageWrapper {
      position: relative;
      width: 100%;
      display: flex;
      align-items: baseline;
      border-radius: 6px 0 0 6px;
      padding-top: 66.7%;

      img, svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }

      svg {
        z-index: 1;
        transition: .3s;

        &:hover polygon, &:hover path {
          animation: none;
          opacity: 1;
          fill: transparent;
          stroke: transparent;
        }


        polygon, path {
          cursor: pointer;
          fill: transparent;
          stroke: transparent;
          transition: .3s;
          animation: showElem 3s ease-out infinite;
          animation-delay: .5s;

          @keyframes showElem {
            0% {
              stroke: transparent;
              fill: transparent;
            }
            50% {
              stroke: $brandColor;
              fill: $brandColor;
            }
            100% {
              stroke: transparent;
              fill: transparent;
            }
          }

          &:hover {
            animation: none !important;
            opacity: 1 !important;
            fill: $brandColor;
            stroke: $brandColor;
          }

          &.inactive:hover {
            fill: $greyColor;
            stroke: $greyColor;
            cursor: default;
          }

          @for $i from 1 through 17 {
            &#B#{$i} {
              animation-delay: .15s * $i;
            }
          }
          @for $i from 1 through 16 {
            &#A#{$i} {
              animation-delay: .15s * $i;
            }
          }
        }
      }

      .tooltip-wrapper {
        display: flex;

        .tooltip-left-part {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          font-family: Medium;
          background: $brandColor;
          padding: 10px 20px;
          border-radius: 4px 0 0 4px;
          height: 66px;
          width: 66px;
          justify-content: center;

          &.inactive-floor {
            background-color: $greyColor;
          }

          &.only-floor {
            border-radius: 4px;
          }

          .building-number {
            font-size: 15px;
            font-family: Bold;
          }

          .building-text {
            font-size: 15px;
            font-family: Medium;
          }
        }

        .tooltip-right-part {
          display: flex;
          flex-direction: column;
          background: white;
          padding: 10px 40px 10px 20px;
          border-radius: 0 4px 4px 0;
          height: 66px;
          white-space: nowrap;
          justify-content: center;

          .floors-count {
            font-size: 14px;
            color: #868686;
            font-family: Regular;
          }

          .houses-count {
            font-size: 16px;
            //color: #202020;
            color: #14ce47;
            font-family: Medium;
          }
        }
      }

      .help-swipe {
        visibility: hidden;
        opacity: 0;
      }

      .view-360 {
        position: absolute;
        top: 20px;
        right: calc((100% - #{$mainContent}) / 2);
        z-index: 4;

        svg {
          transform: translateX(-100%);
          width: 70px;
          height: 70px;

          path {
            animation: none;
            fill: black;
          }
        }
      }
    }
    .Wrapper {
      position: relative;
      width: 100%;
      display: flex;
      align-items: baseline;
      border-radius: 6px 0 0 6px;
      padding-top: 60.7%;

      img, svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }

      svg {
        z-index: 1;
        transition: .3s;

        &:hover polygon, &:hover path {
          animation: none;
          opacity: 1;
          fill: transparent;
          stroke: transparent;
        }


        polygon, path {
          cursor: pointer;
          fill: transparent;
          stroke: transparent;
          transition: .3s;
          animation: showElem 3s ease-out infinite;
          animation-delay: .5s;

          @keyframes showElem {
            0% {
              stroke: transparent;
              fill: transparent;
            }
            50% {
              stroke: $brandColor;
              fill: $brandColor;
            }
            100% {
              stroke: transparent;
              fill: transparent;
            }
          }

          &:hover {
            animation: none !important;
            opacity: 1 !important;
            fill: $brandColor;
            stroke: $brandColor;
          }

          &.inactive:hover {
            fill: $greyColor;
            stroke: $greyColor;
            cursor: default;
          }

          @for $i from 1 through 17 {
            &#B#{$i} {
              animation-delay: .15s * $i;
            }
          }
          @for $i from 1 through 16 {
            &#A#{$i} {
              animation-delay: .15s * $i;
            }
          }
        }
      }

      .tooltip-wrapper {
        display: flex;

        .tooltip-left-part {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          white-space: break-spaces;
          font-family: Medium;
          background: $brandColor;
          padding: 10px 20px;
          border-radius: 4px 0 0 4px;
          height: 70px;
          width: 70px;
          justify-content: center;

          &.inactive-floor {
            background-color: $greyColor;
          }

          &.only-floor {
            border-radius: 4px;
          }

          .building-number {
            font-size: 16px;
            font-family: Bold;
          }

          .building-text {
            font-size: 16px;
            font-family: Medium;
          }
        }

        .tooltip-right-part {
          display: flex;
          flex-direction: column;
          background: white;
          padding: 10px 40px 10px 20px;
          border-radius: 0 4px 4px 0;
          height: 66px;
          white-space: nowrap;
          justify-content: center;

          .floors-count {
            font-size: 14px;
            color: #868686;
            font-family: Regular;
          }

          .houses-count {
            font-size: 16px;
            //color: #202020;
            color: #14ce47;
            font-family: Medium;
          }
        }
      }

      .help-swipe {
        visibility: hidden;
        opacity: 0;
      }

      .view-360 {
        position: absolute;
        top: 20px;
        right: calc((100% - #{$mainContent}) / 2);
        z-index: 4;

        svg {
          transform: translateX(-100%);
          width: 70px;
          height: 70px;

          path {
            animation: none;
            fill: black;
          }
        }
      }
    }

    .soon-wrapper {
      position: relative;

      .hotel_soon_responsive{
        display: none;
      }

      img {
        width: 100%;
      }

      .text {
        width: 60%;
        position: absolute;
        bottom: 50%;
        left: calc((100% - #{$mainContent}) / 2);
        transform: translateY(50%);
        font-size: 160px;
        line-height: 180px;
        font-family: Bold;
        color: white;
        z-index: 2;
        text-transform: uppercase;
      }
    }
  }

  .block-content {
    margin-top: 60px;

    .block {
      display: flex;
      justify-content: space-between;
      width: $mainContent;
      margin: 0 auto 70px;
      position: relative;

      .block_content {
        width: 46%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .content_title {
          font-size: 28px;
          line-height: 36px;
          letter-spacing: 0.03em;
          margin-bottom: 30px;
          font-family: Medium;
        }

        .content_description {
          font-family: Regular;
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.03em;
          *{
            font-family: Regular!important;
          }
        }
      }

      &:nth-child(odd) {

        .block_content {

          .content_title {
            font-size: 46px;
            line-height: 44px;
            font-family: Bold;
            color: $brandColor;
          }
        }

        .blur-circle {
          top: unset;
          left: unset;
          right: 40px;
          bottom: 0;
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .block_content {
        }
      }

      &:nth-child(3n+3) {
        .block_content {
          padding-top: 40px;
          width: 46%;
        }

        .buildings-images {
          width: 46%;
          height: 400px !important;
          object-fit: cover;
        }

        .bg {
          display: block;
          position: absolute;
          left: -20%;
          width: 80%;
          height: calc(100% + 120px);
          background-color: #FADA45;
          z-index: -1;
          opacity: 0.3;
          transform: translateY(50px);
        }

        .blur-circle {
          display: none;
        }
      }

      &:nth-child(4n) {
        .blur-circle {
          position: absolute;
          top: unset;
          left: unset;
          right: 40px;
          bottom: 50%;
        }
      }

      &:nth-child(5n) {
        .blur-circle {
          position: absolute;
          top: -80px;
          right: unset;
          left: -100px;
          bottom: unset;
        }
      }

      .bg {
        display: none;
      }

      .blur-circle {
        position: absolute;
        top: -20px;
        left: -100px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        z-index: -1;
        background-color: $brandColor;
        filter: blur(70px);
        transform: translate(50%, 50%);
      }
    }

    .buildings-images {
      width: 46%;
      border-radius: 7px;
      height: 400px;
      object-fit: cover;
    }

    &.with-distance {
      margin: 100px auto;
    }
  }

  @media all and (max-width: 1050px) {
    .block-content {

      .block {
        padding: 0 $mainPadding;

        .block_content {

          .content_title {
            margin-bottom: 10px;
          }

          .content_description {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &:nth-child(odd) {
          .block_content {

            .content_title {
              font-size: 40px;
              line-height: 50px;
            }
          }
        }

        &:nth-child(3n+3) {
          .block_content {
            .content_title {
              margin-top: 30%;
            }
          }
        }

        .bg {
          display: none;
        }

      }

      .buildings-images {
        width: 46%;
      }

    }
  }

  @media all and (max-width: 1024px) {
    .TopContent {

      .AddressWrapper {
        padding: 0 $mainPadding;
      }

      .ImageWrapper {
        .view-360 {
          right: $mainPadding;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .TopContent {
      .soon-wrapper {
        .hotel_soon{
          display: none;
        }
        .hotel_soon_responsive{
          display: flex;
        }
        .text {
          font-size: 150px;
          line-height: 120px;
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .TopContent {
      .ImageWrapper {
        .view-360 {
          right: $mainPadding;
          top: 5px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .AddressWrapper {
        .address-right-wrapper {
          .parking-wrapper {
            margin-right: 10px;
            height: 40px;
            width: 40px;
            svg{
              height: 100%;
              width: 100%;
            }
          }

          .item-card {
            .icon-wrapper {
              margin-right: 10px;
              width: 40px;
              height: 40px;
            }

            .item-card-content {
              .item-card-content-title {
                font-size: 13px;
              }

              .item-card-content-text {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .block-content {

      .block {
        padding: 0 $mainPadding;

        flex-direction: column;

        .block_content {
          width: 100%;
          margin-bottom: 20px;

          .content_title {
            margin-bottom: 10px;
          }

          .content_description {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &:nth-child(odd) {
          .block_content {

            .content_title {
              font-size: 30px;
              line-height: 40px;
            }
          }
        }

        &:nth-child(even) {
          flex-direction: column;

          .block_content {
          }
        }

        &:nth-child(3n+3) {
          .block_content {
            width: 100%;

            .content_title {
              margin-top: 40px;
            }
          }

          .buildings-images {
            width: 100%;
          }

          .bg {
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            height: 80%;
            transform: unset;
          }
        }

        .bg {
          display: none;
        }

        .blur-circle {
          display: none;
        }
      }

      .buildings-images {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .TopContent {
      .soon-wrapper {
        .text {
          font-size: 80px;
          line-height: 100px;
        }
      }
    }
  }
}


