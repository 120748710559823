@import "../mainVariables";

.headerWrapper {
  height: 96px;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10000;

  &.dark {
    background-color: $darkColor;
    height: $headerHeight;
  }

  .menu-wrapper {
    width: $mainContent;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  .left-side {
    width: 50%;
    display: flex;
    align-items: center;

    .logo-wrapper {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 14%;

      img {
        width: 170px;
        height: 100%;
      }
      svg{
        width: 170px;
        height: 100%;
      }
    }
  }

  .right-side {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .header-phone {
      height: 40px;
      color: white;
      border-radius: 4px;
      border-bottom: none;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 16px;

      &__icon {
        margin-left: 20px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: $brandColor;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;

        &:hover {
          background-color: $brandSecColor;
        }
      }


      span {
        margin-top: 2px;
      }
    }

    .language-menu {
      color: white;
      margin-left: 94px;
      cursor: pointer;
      font-family: Regular;
      width: 44px;
      height: 32px;
      img{
        width: 28px;
        height: 24px;
      }
    }
  }

  &__burger_wrapper {
    width: 72px;
    height: 72px;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
    background-color: $brandColor;
    position: relative;
    cursor: pointer;
    margin-left: 44%;

    &:hover{
      &__burger::after {
        width: 38px;
      }
    }
  }

  &__burger {
    position: relative;
    left: -4px;
    width: 25px;
    height: 19px;
    transition: all .3s cubic-bezier(.785, .135, .15, .86);

    &.is-active {
      left: unset;
      right: calc((100% - #{$mainContent}) / 2 + 26px);
      z-index: 1010;
      position: fixed;
    }
  }

  &__burger::after, &__burger::before {
    content: "";
    position: absolute;
    left: 0;
    width: 38px;
    height: 4px;
    overflow: hidden;
    background: black;
    transition: width .3s cubic-bezier(.785, .135, .15, .86), background .3s cubic-bezier(.785, .135, .15, .86), transform .3s cubic-bezier(.785, .135, .15, .86);
  }

  &__burger::before {
    top: 0;
  }

  &__burger::after {
    top: 12px;
    width: 27px;
  }

  &__burger.is-active::before {
    transform: rotate(45deg) translate3d(0, 8.5px, 0);
  }

  &__burger.is-active::after {
    width: 38px;
    -webkit-transform: rotate(-45deg) translate3d(0, -8.5px, 0);
    transform: rotate(-45deg) translate3d(0, -8.5px, 0);
  }

  &__burger:hover::after {
    width: 38px;
  }

  &.fullscreen-menu {
    .menuWrapper::before {
      transform: none;
    }
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    margin: unset;
    height: 56px;
    padding-left: $mainPadding;
    background-color: $darkColor;

    &.dark {
      height: unset;
    }

    .left-side {
      .logo-wrapper {
        height: 20px;
      }
    }
    .right-side {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .header-phone, {
        display: none;
      }
    }
    &__burger_wrapper {
      width: 56px;
      height: 56px;
    }
    &__burger {
      width: 30px;
      height: 19px;
    }
  }

  @media screen and (max-width: 700px) {

    .left-side {
      .PrimaryButton {
        display: none;
      }
    }
  }
}
