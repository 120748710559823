@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mainVariables";

.HomepageAbout {
  padding-top: 125px;
  background: rgb(245, 244, 242);
  background: linear-gradient(180deg, rgba(245, 244, 242, 1) 0%, rgba(255, 255, 255, 1) 250px);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .blur-circle {
    position: absolute;
    top: 60px;
    left: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    z-index: 1;
    background-color: $brandColor;
    filter: blur(70px);
    transform: translate(50%, 50%);

    &.right {
      right: 60px;
      top: 0;
      left: unset;
      transform: translate(-50%, 50%);
    }
  }

  .HomepageAboutTitle {
    font-family: Medium;
    font-size: 40px;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0.01em;
    color: #222222;
    width: $mainContent;
    margin: auto;
    margin-bottom: 60px;
    position: relative;
    z-index: 2;
  }

  .HomepageAboutContent {
    width: $mainContent;
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: relative;

    .main-img-wrapper {
      width: $halfContent;
      position: relative;

      .main-img-content {
        position: relative;
        width: 100%;
        aspect-ratio: 1;

        .play-btn {
          position: absolute;
          top: 43%;
          left: 43%;
          z-index: 4;
          cursor: pointer;

          svg {
            transition: all 0.7s;

            path {
              transition: all 0.3s;
            }

            rect {
              transition: all 0.7s;
            }
          }

          &:hover {
            svg {
              transform: scale(1.07, 1.07);

              path {
                fill: $brandColor;
              }

              rect {
                fill: black;
              }
            }
          }
        }

        .main-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 16px;
        }

        .about-box {
          z-index: 5;
          width: 260px;
          padding: 32px 6px 15px 25px;
          border-radius: 0 0 24px 0;
          background-color: #21201D;
          position: absolute;
          bottom: -40px;
          right: -77px;

          &__title {
            font-size: 28px;
            font-weight: 500;
            height: 36px;
            display: flex;
            align-items: center;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            letter-spacing: 0.03em;
            color: #FADA45;
            margin-bottom: 18px;
          }

          &__description {
            max-height: 88px;
            overflow: hidden;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.03em;
            text-align: left;
            color: #D6D6D6;
            margin-bottom: 24px;
          }

          svg {
            transition: transform 0.2s;

            path {
              fill: white;
            }
          }

          &:hover {
            svg {
              transform: translate(3px, -3px);
            }
          }
        }
      }
    }

    .FeaturesWrapper {
      font-size: 16px;
      font-family: Regular;
      width: $halfContent;
      top: 0;
      right: 0;
      height: 100%;
      padding-bottom: 8px;
      align-items: flex-start;

      .features-title {
        font-family: Medium;
        margin-bottom: 40px;
        font-size: 26px;
        font-weight: 500;
        line-height: 45px;
        letter-spacing: 0.03em;
        text-align: left;
        color: #1E1E1B;
      }

      ul {
        li {
          display: flex;
          align-items: center;
          list-style: none;
          position: relative;
          font-size: 22px;
          font-weight: 400;
          line-height: 34px;
          letter-spacing: 0.03em;
          text-align: left;
          color: #484848;
          margin-bottom: 12px;

          svg {
            margin-right: 20px;
          }
        }
      }

      .question-wrap {
        display: flex;
        align-items: center;
        margin-top: 95px;

        &__question {
          max-width: calc(100% - 120px);
          margin-right: 50px;
          font-size: 23px;
          font-weight: 400;
          line-height: 34px;
          letter-spacing: 0.03em;
          text-align: left;
          color: #222222;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .PrimaryButton {
          background-color: transparent;
          height: 40px;
          border: 1px solid #222222;
          border-radius: 6px;

          .title {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.03em;
            text-align: left;
          }
        }
      }

      .counts {
        display: flex;
        //justify-content: space-between;
        margin-top: 89px;

        &__item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 28px;
          height: 120px;
          width: 180px;
          margin-right: 30px;
          background-color: #F4F4F4;
          border-radius: 24px;

          &__count {
            font-family: Medium;
            font-size: 40px;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: 0.03em;
            text-align: left;
            color: #222222;
          }

          small {
            font-size: 16px;
            font-weight: 200;
            line-height: 22px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #222222;
            margin-top: 4px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .linkWrapper {
      line-height: 26px;
      letter-spacing: 0.4px;
      margin-bottom: 80px;

      a {
        color: #007AFE;
        text-decoration: underline;
      }
    }

    .btnsWrapper {
      display: flex;
      justify-content: space-between;

      button {
        height: 58px;
        text-transform: uppercase;
        width: calc(50% - 10px);
      }
    }
  }

  .sec-title {
    width: $mainContent;
    font-size: 40px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #222222;
    margin: 140px auto 50px;
  }
  .sec-block-wrapper{
    width:$mainContent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 140px;
    .sec-block-item{
      width: calc(100% / 3 - 24px);
      .img_wrapper{
        padding: 4px;
        border: 4px solid #D6D6D6;
        transition: all .5s ease, opacity .5s ease;
        border-radius: 20px;
        overflow: hidden;
        .project-img{
          width: 100%;
          height: 328px;
          object-fit: cover;
          border-radius: 12px;
          transition: 1s;
          display: block;
        }
      }
      .project-title{
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.03em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-top: 36px;
      }

      &:hover{
        .img_wrapper{
          border: 4px solid #FADA45;
          .project-img{
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .about-video-player-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 1;
      cursor: pointer;

      svg {
        path {
          transition: all 0.2s;
        }
      }

      &:hover {
        svg {
          path {
            fill: white;
          }
        }
      }
    }

    .player-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.83);
    }

    .about-player {
      overflow: hidden;
      z-index: 1;
      width: 70%;
      border-radius: 12px;
      background-color: black;
      //aspect-ratio: 1040/585;
      .video-react-big-play-button {
        display: none;
      }

      video {
        //object-fit: cover;
      }
    }

    @media all and (max-width: 700px) {
      .about-player {
        width: 95%;
      }
    }
  }


  @media all and (max-width: 1400px) {
    .HomepageAboutContent {
      .FeaturesWrapper {
        .counts {
          &__item {
            padding: 0 20px;
            width: 160px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1200px) {
    .HomepageAboutContent {
      .FeaturesWrapper {
        .counts {
          justify-content: space-between;

          &__item {
            padding: 0 20px;
            width: calc(31%);
            height: 100px;
            margin-right: 0;

            &__count {
              font-size: 24px;
            }

            small {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1024px) {
    padding: 34px $mainPadding;
    .HomepageAboutContent {
      flex-direction: column;

      .main-img-wrapper {
        width: calc(100% - 50px);
      }

      .FeaturesWrapper {
        width: 100%;
        margin-top: 75px;
      }
    }
  }

  @media all and (max-width: 700px) {
    position: unset !important;
    padding-top: 50px;
    .blur-circle {
      display: none;
    }

    .HomepageAboutTitle {
      margin-bottom: 31px;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0.03em;
    }

    .HomepageAboutContent {
      flex-direction: column;

      .main-img-wrapper {
        width: 100%;
        padding-right: 50px;

        .main-img-content {
          min-height: unset;

          .play-btn {
            svg {
              width: 56px;
              height: 56px;
            }
          }

          img {
            border-radius: unset !important;
          }

          .about-box {
            width: 246px;
            //height: 170px;
            padding: 22px 6px 15px 24px;
            border-radius: 0 0 12px 0;
            bottom: -50px;
            right: -50px;

            &__title {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 8px;
            }

            &__description {
              font-size: 12px;
              line-height: 18px;
              margin-bottom: 10px;
              color: #BABBBB;
            }

            svg {
              position: absolute;
              right: 14px;
              bottom: 14px;
              width: 18px;
              height: 18px;
            }
          }
        }


      }

      .FeaturesWrapper {
        .features-title {
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.03em;
          text-align: left;
          margin-bottom: 24px;
        }

        ul {
          li {
            font-size: 19px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.03em;
            text-align: left;
            margin-bottom: 20px;
          }
        }

        .question-wrap {
          margin-top: 40px;

          &__question {
            max-width: calc(100% - 80px);
            margin-right: 24px;
            font-size: 19px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.03em;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .counts {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;

          &__item {
            width: calc(100% / 3 - 20px);
            //padding: unset;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100px;
            border-radius: 16px;

            &__count {
              font-family: Medium;
              font-size: 30px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0.03em;
              text-align: left;
              color: #222222;
            }

            small {
              font-size: 14px;
              font-weight: 300;
              line-height: 18px;
              letter-spacing: 0.06em;
              text-align: left;
              color: #222222;
              margin-top: 12px;
            }
          }
        }
      }
    }

    .sec-title {
      margin: 56px auto 0;
      font-size: 24px;
      line-height: 29.3px;
      letter-spacing: 0.01em;
      text-align: left;
    }
    .sec-block-wrapper{
      width:100%;
      flex-direction: column;
      margin-bottom: 72px;
      .sec-block-item{
        width: 100%;
        margin-top: 40px;
        .project-img{
          width: 100%;
          height: 290px!important;
        }
        .project-title{
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.03em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }

      @media screen and (max-width: 400px){
        .sec-block-item{
          .project-img{
            width: 100%;
            height: 200px!important;
          }
          .project-title{
            font-size: 18px;
            margin-top: 20px;
          }
        }
      }
    }
  }

  @media all and (max-width: 500px) {
    .HomepageAboutContent {
      .main-img-wrapper {
        .main-img-content {
          .about-box {
            bottom: -85px;
            right: -50px;
          }
        }
      }

      .FeaturesWrapper {
        margin-top: 118px;
        .features-title {
          font-size: 20px;
          font-family: Medium;
          font-weight: 400;
        }
        ul {
          li {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .counts {
          &__item {
            width: calc(100% / 3 - 10px);
            height: 80px;
            padding: 0 15px;

            &__count {
              font-family: Medium;
              font-size: 20px;
            }

            small {
              font-size: 11px;
              line-height: 15px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 400px) {
    .HomepageAboutContent {
      .main-img-wrapper {
        .main-img-content {
          .about-box {
            bottom: -100px;
          }
        }
      }
      .FeaturesWrapper{
        .counts {
          &__item {
            width: calc(100% / 3 - 5px);
            height: 70px;
            padding: 0 10px;

            &__count {
              font-size: 18px;
            }

            small {
              font-size: 10px;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}
