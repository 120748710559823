@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.AboutWrapper {
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;

  .CountsWrapper {
    padding-top: calc(33% - 80px);
    position: relative;

    .counts-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .48);
      padding: $mainPadding;

      .about-main-title {
        width: $mainContent;
        font-size: 3.5vw;
        font-family: Bold;
        color: white;
        text-transform: uppercase;
        margin: auto;
        margin-bottom: calc(#{$mainPadding} / 2);
      }

      .counts-main-content {
        width: $mainContent;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .counts-item {
          opacity: 0;
          text-align: center;
          text-transform: uppercase;
          color: white;
          animation: fadeIn 2s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;

          &:nth-child(1) {
            animation-delay: 1 * .2s;
          }
          &:nth-child(3) {
            animation-delay: 3 * .2s;
          }
          &:nth-child(5) {
            animation-delay: 5 * .2s;
          }
          &:nth-child(7) {
            animation-delay: 7 * .2s;
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .count {
            color: $brandColor;
            font-size: 4.5vw;
            font-family: Bold;
            font-weight: 900;
          }

          .count-title {
            font-size: 17px;
            font-family: Bold;
          }
        }

        .line {
          opacity: 0;
          border-left: 1px solid white;
          height: 100%;
          animation: fadeIn 2s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;

          &:nth-child(2) {
            animation-delay: 2 * .2s;
          }
          &:nth-child(4) {
            animation-delay: 4 * .2s;
          }
          &:nth-child(6) {
            animation-delay: 6 * .2s;
          }
        }
      }
    }
  }

  .AboutMainContent {
    padding: $mainPadding 0;
    background: white;
  }

  .AboutTextContent {
    //padding: 0 $mainPadding;
    width: $mainContent;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    .slider-wrapper {
      width: 47%;

      &.LeftImgWrapper {
        width: 47%;
      }

      .images-list-wrapper {
        width: calc(100% + 5px);
        margin-top: 12px;
        position: relative;
        cursor: pointer;

        .slick-track {
          margin-left: unset;
        }

        .slick-slide {
          padding-right: 12px;
          outline: none;

          .list-item {
            width: 100%;
            padding-top: 100%;
            position: relative;
            cursor: pointer;
            border-radius: 8px;

            &:focus {
              outline: none;
            }

            .list-img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5px;

              &:focus {
                outline: none;
              }
            }

            &.active::after {
              content: '';
              position: absolute;
              bottom: -4px;
              width: 100%;
              height: 2px;
              background: $brandColor;
            }

          }
        }

        &:hover .slider-btn {
          visibility: visible;
        }

        .slider-btn {
          visibility: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 42px;
          height: 42px;
          transition: .2s;
          background-color: white;
          box-shadow: 0 1px 4px 0 rgba(10, 12, 11, .2);

          img, svg {
            width: 16px;
            height: 16px;

            path {
              fill: black;
            }
          }

          &:hover svg {
            path {
              fill: $brandColor;
            }
          }

          &:disabled {
            visibility: hidden;
          }

          &.prev-btn {
            left: -20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &.next-btn {
            right: -12px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .main-img-wrapper {
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 8px;

        .main-img-content {
          position: relative;
          width: 100%;
          padding-top: 71.4%;

          .main-img {
            border-radius: 8px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .product-current-img {
          width: 100% !important;
          height: 100% !important;
        }

        .mobile-slider-img {
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          display: none;
        }
      }
    }

    .TextWrapper {
      font-size: 16px;
      font-family: Regular;
      width: 47%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 8px;

      &.LeftTextWrapper {
        top: 0;
        left: $mainPadding;
        right: unset;
        width: 47%;

        .description {
          margin-bottom: 0;
        }
      }

      .wrapper-title {
        font-family: Medium;
        letter-spacing: 0.4px;
        margin-bottom: 50px;
      }

      .description {
        flex-grow: 1;
        line-height: 26px;
        letter-spacing: 0.4px;
        overflow: hidden;
        margin-bottom: 30px;
        column-count: 1;
      }

      .btnsWrapper {
        display: flex;
        justify-content: space-between;

        button {
          height: 58px;
          text-transform: uppercase;
          width: calc(50% - 10px);
        }
      }
    }
  }

  .features-list {
    width: $mainContent;
    margin: auto;
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;

    .features-item {
      width: 25%;
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        transition: .2s;
      }

      &:hover img {
        transform: scale(1.1);
      }

      span {
        width: calc(100% - 70px);
        line-height: 26px;
        letter-spacing: 0.4px;

        &::first-line {
          word-spacing: 100vw;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid #d8d8d8;
      }

      &:not(:first-child) {
        img {
          margin-left: 20px;
        }
      }
    }
  }

  @media all and (max-width: 1600px) {

    .AboutTextContent {

      .slider-wrapper {
        //width: 46%;

        &.LeftImgWrapper {
          //width: 48%;
        }
      }

      .TextWrapper {

        //width: 44%;
        font-size: 15px;

        //&.LeftTextWrapper {
        //  width: 42%;
        //}

        .wrapper-title {
          margin-bottom: 30px;
        }

        .description {
          margin-bottom: 60px;
        }
      }
    }
  }

  @media all and (max-width: 1300px) {

    .AboutTextContent {

      .slider-wrapper {
        width: 48%;

        //&.LeftImgWrapper {
        //  width: 42%;
        //}
      }

      .TextWrapper {
        width: 48%;
        font-size: 15px;

        &.LeftTextWrapper {
          width: 48%;
        }

        .description {
          line-height: 22px;
          font-size: 15px;
          margin-bottom: 30px;
        }

        .btnsWrapper {
          button {
            font-size: 15px;
            height: 42px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1024px) {
    background-size: cover;

    .AboutMainContent {
      padding: $mainPadding;
      padding-bottom: 80px;
    }

    .CountsWrapper {
      height: 300px;
      .counts-bg {
        padding: 80px $mainPadding;
      }
    }

    .AboutTextContent {
      flex-direction: column;


      .slider-wrapper {
        width: 100%;

        &.LeftImgWrapper {
          width: 100%;
          order: -1;
        }

        .images-list-wrapper {
          .slider-btn {
            visibility: hidden;
          }
        }
      }

      .TextWrapper {
        position: unset;
        width: 100%;
        margin-top: 30px;
        font-size: 14px;

        &.LeftTextWrapper {
          width: 100%;
        }

        .wrapper-title {
          font-size: 3.5vw;
          margin-bottom: 30px;
        }

        .description {
          font-size: 14px;
          //max-height: 440px;
        }

        .btnsWrapper {
          button {
            font-size: 14px;
            height: 45px;
          }
        }
      }
    }
    .features-list {

      .features-item {
        width: 50%;
        display: flex;
        align-items: center;

        img {
          width: 50px;
          height: 50px;
          margin-right: 20px;

          &:hover path {
            transition: .2s;
            fill: $brandColor;
          }
        }

        span {
          width: calc(100% - 70px);
          line-height: 26px;
          letter-spacing: 0.4px;

          &::first-line {
            word-spacing: 100vw;
          }
        }

        &:nth-child(2n) {
          border-left: 1px solid #d8d8d8;

          img {
            margin-left: 20px;
          }

          span {
            width: calc(100% - 90px);
          }
        }

        &:nth-child(2n+1) {
          border-right: 1px solid #d8d8d8;
        }

        &:first-child {
          border-bottom: 1px solid #d8d8d8;
          padding: 0 20px 20px 0;
        }

        &:last-child {
          padding: 20px 0 0 0;
          border-top: 1px solid #d8d8d8;

        }

        &:nth-child(2) {
          padding: 0 0 20px 0;
          border-bottom: 1px solid #d8d8d8;
          border-right: 0;
        }

        &:nth-child(3) {
          padding: 20px 20px 0 0;
          border-top: 1px solid #d8d8d8;

          img {
            margin-left: 0;
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .CountsWrapper {
      .counts-bg {
        .counts-main-content {
          flex-wrap: wrap;

          .line {
            display: none;
          }

          .counts-item {
            width: 50%;

            &:first-child {
              border-bottom: 1px solid #d8d8d8;
              padding: 0 20px 20px 0;
              border-right: 1px solid #d8d8d8;
            }

            &:last-child {
              padding: 20px 0 0 0;
            }

            &:nth-child(3) {
              padding: 0 0 20px 0;
              border-bottom: 1px solid #d8d8d8;
              border-right: 0;
            }

            &:nth-child(5) {
              padding: 20px 20px 0 0;
              border-right: 1px solid #d8d8d8;

              img {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 550px) {

    .CountsWrapper {
      height: 200px;
      .counts-bg {
        .about-main-title {
          font-size: 5vw;
          margin-bottom: 10px;
        }
        .counts-main-content {
          .counts-item {

            .count {
              font-size: 36px;
            }
            .count-title {
              font-size: 15px;
            }
          }
        }
      }
    }
    .AboutMainContent {
      padding-top: 20px;
    }

    .AboutTextContent {

      .slider-wrapper {
        .images-list-wrapper {
          .slider-btn {
            width: 30px;
            height: 30px;

            img, svg {
              width: 12px;
              height: 12px;
            }

            &.prev-btn {
              left: -15px;
            }

            &.next-btn {
              right: -5px;
            }
          }
        }
      }

      .TextWrapper {
        width: 100%;
        margin-top: 30px;
        font-size: 14px;
      }
    }

    .features-list {
      padding: 30px $mainPadding;

      .features-item {
        width: 50%;

        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        span {
          width: calc(100% - 60px);
          line-height: 22px;
        }

        &:nth-child(2n) {

          img {
            margin-left: 10px;
            margin-right: 10px;
          }

          span {
            width: calc(100% - 80px);
          }
        }

        &:first-child {
          padding: 0 10px 10px 0;
        }

        &:last-child {
          padding: 10px 0 0 0;
        }

        &:nth-child(2) {
          padding: 0 0 10px 0;
        }

        &:nth-child(3) {
          padding: 10px 10px 0 0;
        }
      }
    }
  }
  @media all and (max-width: 450px) {

    .AboutTextContent {

      .slider-wrapper {
        .images-list-wrapper {
          .slider-btn {
            width: 25px;
            height: 25px;

            img, svg {
              width: 10px;
              height: 10px;
            }

            &.prev-btn {
              left: -10px;
            }

            &.next-btn {
              right: 0px;
            }
          }
        }
      }

      .TextWrapper {
        font-size: 12px;

        .wrapper-title {
          font-size: 4.5vw;
          margin-bottom: 20px;
        }

        .description {
          line-height: 20px;
        }

        .btnsWrapper {
          button {
            font-size: 12px;
            height: 40px;
            padding: 0 20px;
          }
        }
      }
    }
    .features-list {
      margin-bottom: 20px;

      .features-item {

        img {
          width: 32px;
          height: 32px;
        }

        span {
          font-size: 13px;
          line-height: 18px;
          &::first-line {
            word-spacing: unset;
          }
        }
      }
    }
  }
  @media all and (max-width: 390px) {
    .CountsWrapper {
      .counts-bg {
        .about-main-title {
          font-size: 7vw;
          margin-bottom: 30px;
        }
      }
    }

    .AboutTextContent {
      .TextWrapper {
        .wrapper-title {
          font-size: 5vw;
        }

      }
    }
    .features-list {
      padding: 20px $mainPadding;

      .features-item {
        width: 100%;
        border: none;

        &:nth-child(2n) {

          img {
            margin-left: 0px;
          }

          span {
            width: calc(100% - 70px);
          }
        }

        &:first-child {
          border-right: 0;
        }

        &:last-child {
          border-left: 0;
        }

        &:nth-child(2) {
          padding-top: 10px;
          border-left: 0;
          border-bottom: 0;
        }

        &:nth-child(3) {
          border-right: 0;
          padding-bottom: 10px;
        }
      }
    }
  }
}