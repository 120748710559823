@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.home-image-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 720px;
  background: rgba(245, 244, 242, 1);

  .home-image-block {
    width: $mainContent;
    height: 100%;
    position: relative;

    .main-slider-items {
      position: relative;
      width: 100%;
      height: 100%;


      .overlay_blur {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(262.53deg, rgba(11, 11, 11, 0.5) 22.24%, rgba(20, 20, 20, 0.5) 98.14%);
        backdrop-filter: blur(9px);
        z-index: 1;
      }

      .overlay {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(262.53deg, rgba(0, 0, 0, 0.15) 22.24%, rgba(0, 0, 0, 0.15) 98.14%);
        backdrop-filter: unset;
        z-index: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        //border-radius: 0 0 20px 20px;

        .overlay-inner {
          max-width: 640px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .overlay-title {
            font-size: 50px;
            font-weight: 400;
            line-height: 55px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #FEFEFE;
          }

          .overlay-description {
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.01em;
            text-align: center;
            margin-top: 20px;
            color: #FEFEFE;
          }
        }
      }

      .main-slider-inner-part {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0 0 20px 20px;
        //overflow: hidden;

        .slider_prev_btn, .slider_next_btn{
          position: absolute;
          top: 47%;
          width: 56px;
          height: 56px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #2222224D;
          z-index: 10;
          cursor: pointer;
          display: none;
        }

        .slider_next_btn{
          right: -28px;
        }
        .slider_prev_btn{
          rotate: 180deg;
          left: -28px;
        }

        .slider_mobile_wrapper {
          display: none;
        }

        .slick-current {
          z-index: 1;
        }

        .slider-item {
          width: 100%;
          height: 720px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .video-wrapper {
            width: 100%;
            height: 100%;

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .mobile-block {
        display: none;
      }

    }

    .projects-wrapper {
      display: flex;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 480px;

      .project-item {
        width: 25%;
        position: relative;

        &__inner {
          width: 100%;
          padding-top: 100%;
          position: relative;
          overflow: hidden;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.6s ease-out;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:first-child {
          width: 50%;

          .project-item__inner {
            padding-top: 50%;
          }
        }

        &:not(:last-child) {
          margin-right: 5px;
        }

        .project-item-info {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: black;
          z-index: 10;
          opacity: 0.3;
          padding: 0 70px;
          display: flex;
          align-items: center;
          transition: all 0.4s ease-out;
          overflow: hidden;
        }

        .content {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 20;
          transition: all 0.2s;
          opacity: 0;
          padding: 0 40px 0;

          .title {
            text-align: center;
            overflow: hidden;
            font-family: Medium;
            font-weight: 400;
            font-size: 28px;
            line-height: 45px;
            max-height: 135px;
            letter-spacing: 0.03em;
            color: #222222;
          }

          .project_link_wrapper {
            display: flex;
            justify-content: center;
            //margin-top: 20px;
          }

          //.more {
          //  font-family: Medium;
          //  font-size: 19px;
          //  line-height: 140%;
          //  letter-spacing: 0.03em;
          //  display: flex;
          //  align-items: center;
          //  cursor: pointer;
          //
          //  svg {
          //    transform: rotate(-90deg);
          //    transition: transform 0.2s;
          //  }
          //}
        }

        &:hover {
          img {
            transform: scale(1.2);
          }

          .project-item-info {
            opacity: 0.8;
            //background-color: $brandColor;
            background-color: #FADA45;

          }

          .content {
            opacity: 1;
          }
        }
      }
    }


    .loading {
      filter: blur(10px);
      clip-path: inset(0);
    }

    .loaded {
      filter: blur(0px);
      transition: filter 0.5s linear;
    }
  }

  .slider_link_button {
    margin-top: 20px;
    width: 56px;
    height: 56px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FEFEFE;
    &.project_button{
      background-color: #222222;
      svg{
        path{
          fill: white;
        }
      }
    }

  }

  @media all and (max-width: 1640px) {
    .projects-wrapper {
      height: 400px;
    }
  }

  @media all and (max-width: 1440px) {
    .home-image-block {
      .projects-wrapper {
        height: 360px;

        .project-item {
          .project-item-info {
            padding: 30px;
          }

          .content {
            padding: 0 20px 0;

            .title {
              font-size: 24px;
              line-height: 40px;
              max-height: 120px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1250px) {
    .home-image-block {
      .projects-wrapper {
        .project-item {
          .content {
            padding: 0 15px 0;

            .title {
              font-size: 20px;
              line-height: 25px;
              max-height: 75px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    height: auto;
    .slider_link_button {
      width: 46px;
      height: 46px;
    }
    .home-image-block {
      .pagination_wrapper {
        display: none;
      }

      .main-slider-items {
        .main-slider-inner-part {
          position: relative;
          width: 100%;
          border-radius: 0;

          .slider_prev_btn, .slider_next_btn{
            width: 50px;
            height: 50px;
            top: 30%;
            display: flex;
          }
          .slider_prev_btn{
            left: 12px;
          }
          .slider_next_btn{
            right: 12px;
          }

          .slider-item {
            width: 100%;
            height: auto;
            aspect-ratio: 480/410;
          }

          .overlay_blur {
            display: none;
          }

          .overlay {
            display: flex;

            .overlay-inner {
              .overlay-title {
                font-size: 26px;
                font-weight: 400;
                line-height: 40px;
              }

              .overlay-description {
                margin-top: 16px;
                font-size: 13px;
              }
            }
          }

          .projects-wrapper {
            display: none;
          }

          .slider_mobile_wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 20px;
            padding: 0 $mainPadding;

            &.small_size {
              height: 20px;
              overflow: hidden;
            }

            .progress-wrapper {
              top: 13px;
            }

            .mobile_title {
              font-family: Medium;
              font-size: 24px;
              font-weight: 400;
              line-height: 30px;
              color: #272727;
              margin-top: 35px;
              max-height: 64px;
              overflow: hidden;
            }

            .mobile_address {
              font-family: Medium;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.03em;
              color: #272727;
              margin-top: 13px;
            }
          }
        }
      }

      .projects-wrapper {
        display: none;
        //flex-wrap: wrap;
        //height: unset;
        //position: unset;
        //justify-content: space-between;
        //
        //.project-item {
        //  width: calc(50% - 1.5px);
        //
        //  img {
        //    width: 100%;
        //  }
        //
        //  &:first-child {
        //    width: 100%;
        //    margin-bottom: 3px;
        //  }
        //
        //  &:not(:last-child) {
        //    margin-right: 0;
        //  }
        //
        //  &:nth-child(2) {
        //    margin-right: 3px;
        //  }
        //}
      }
    }
  }

  @media all and (max-width: 500px) {
    .home-image-block {
      .main-slider-items {
        .main-slider-inner-part {
          .slider_mobile_wrapper {
            min-height: 130px;
            &.small_size {
              min-height: 20px;
            }
          }
        }
      }
    }
  }
}



