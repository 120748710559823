@mixin custom-slider {
  .slider-filter {
    width: 100%;

    .slider-filter-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.03em;
      color: #8D8D8D;

      div{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: left;
        color: #222222;
      }

      .slider-label{
       margin-right: 10px;
      }
      span {
        //margin: 0 10px;
      }
    }

    .ant-slider {
      margin: 10px 12px 0 12px;

      .ant-slider-rail {
        height: 1px;
        background-color: $greyBorderColor;
      }

      .ant-slider-handle {
        border: 1px solid $greyBorderColor;
        background-color: white;
        margin-top: -6px;
      }

      .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
        box-shadow: 0 0 0 5px rgba(47, 47, 47, 0.1);
        border-color: $greyBorderColor;
      }

      .ant-slider-handle:focus {
        box-shadow: 0 0 0 5px rgba(47, 47, 47, 0.1)
      }
    }

    .ant-slider:hover > .ant-slider-track {
      background-color: $greyBorderColor;
    }

    .ant-slider-track {
      background-color: $greyBorderColor;
      height: 2px;
    }


    &.dark{
      .slider-filter-top {
        color: #656565;
      }

      .ant-slider {
        .ant-slider-rail {
          background-color: #656565;
        }
        .ant-slider-handle {
          border: 1px solid #656565;
        }
        .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
          border-color: #656565;
        }
      }
      .ant-slider:hover > .ant-slider-track {
        background-color: #656565;
      }
      .ant-slider-track {
        background-color: #656565;
      }
    }
  }
}

@mixin custom-scrollbar-x {
  &::-webkit-scrollbar {
    height: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}

@mixin custom-scrollbar-y {
  &::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 20px solid rgba(255, 213, 18, 0.4);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.65,0,.34,1);
  z-index: -1;

  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale3d(0.75,0.75,1);
    }

    to {
      opacity: 0;
      transform: scale3d(1.5,1.5,1);
    }
  }
}